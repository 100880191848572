import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ToastrService } from 'ngx-toastr';
import { BroadcastService } from '@app/services/broadcast.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CropperComponent } from '../image-cropper/cropper.component';
@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss'],
})
export class UserSettingComponent implements OnInit {
  croperModalRef: BsModalRef;
  resetPassword: FormGroup;
  subject: Subject<boolean>;
  tab = 'profile';
  profile: any;
  profilePicUrl = '';
  userProfile = {
    firstLanguage: '',
    firstName: '',
    fullName: '',
    id: 0,
    isActive: false,
    lastName: '',
    otherLanguage: 'Frnch',
    profilePicUrl: '',
    role: '',
    gender: '',
  };
  isNotifications: boolean;
  submitted = false;
  loading = false;
  picLoading = false;
  privacySetting: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService,
    private _broadcastService: BroadcastService,
    private modalService: BsModalService
  ) {}
  ngOnInit(): void {
    this.profile = this.authService.getProfileFromToken();
    this.user(this.profile.id);
    this.changeTab('profile');
    this.resetPassword = this.formBuilder.group(
      {
        oldPassword: ['', Validators.required],
        newPassword: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      }
    );
  }
  get verifyForm() {
    return this.resetPassword.controls;
  }
  user(id: any) {
    this.authService.user(id).then((res) => {
      if (res === undefined) {
        return;
      }
      console.log('res profile', res);
      this.profilePicUrl = res.profilePicUrl;
      this.isNotifications = res.isNotifications;
      this.privacySetting = res.privacySetting === 'public' ? true : false;
      this.userProfile = {
        firstLanguage: res.firstLanguage,
        firstName: res.firstName,
        fullName: res.fullName,
        id: res.id,
        isActive: res.isActive,
        lastName: res.lastName,
        otherLanguage: res.otherLanguage,
        profilePicUrl: res.profilePicUrl,
        role: res.role,
        gender: res.gender,
      };
    });
  }
  updateProfile(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.loading = true;
    this.userProfile.fullName = `${this.userProfile.firstName} ${this.userProfile.lastName}`;
    this.authService.updateUser(this.userProfile).then((res) => {
      if (res === undefined) {
        this.loading = false;
        return;
      }
      this.loading = false;
      this._broadcastService.sendMessage({ type: 'updateProfile', message: true });
    });
  }
  changeTab(tab: any) {
    $('.setting-bar li a').on('click', function () {
      $('.setting-bar li a').removeClass('active');
      $(this).addClass('active');
    });
    this.changeRightBar(tab);
  }
  changeRightBar(tab: any) {
    $('.setting-bar-right li').removeClass('active');
    $('.setting-bar-right li').removeClass('show');
    $(`#${tab}`).addClass('active');
    $(`#${tab}`).addClass('show');
  }
  logout() {
    this.authService.logout();
    this.confirmMessge(true);
    this.router.navigate(['/dashboard']);
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  changePassword() {
    this.submitted = true;
    if (!this.resetPassword.valid) {
      return;
    }
    let obj = {
      id: this.profile.id,
      oldPassword: this.resetPassword.value.oldPassword,
      newPassword: this.resetPassword.value.confirmPassword,
    };
    this.loading = true;
    this.authService.changePassword(obj).then((res) => {
      if (res === undefined) {
        this.loading = false;
        return;
      }
      this.loading = false;
    });
  }
  async handleFileInput(file: any) {
    this.picLoading = true;
    const formData = new FormData();
    let operations: any = {
      query: `
        mutation($file: Upload!) {
          uploadFile(file: $file) {
            Key
            Location
          }
        }
      `,
      variables: {
        file: null,
      },
    };
    let _map = {
      file: ['variables.file'],
    };
    var fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file);
    this.authService.uploadImage(fd).then((res: any) => {
      if (res.data.errors) {
        this.picLoading = false;

        res.data.errors &&
          res.data.errors.map((err: any) => {
            this.toastr.error(err.message, 'Error');
          });
        return;
      }
      this.profilePicUrl = res.data.data.uploadFile.Location;
      this.userProfile.profilePicUrl = this.profilePicUrl;
      this.picLoading = false;
    });
  }
  showCropper() {
    const subject = new Subject<any>();
    this.croperModalRef = this.modalService.show(CropperComponent, {
      class: 'modal-md-invitePeople modal-dialog-centered',
    });
    this.croperModalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data) {
        let file = this.dataURLtoFile(data.image, data.original.name);
        this.handleFileInput(file);
        this.croperModalRef.hide();
      } else {
        this.croperModalRef.hide();
      }
    });
  }
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  async changeSettingNotifications(event: any) {
    console.log('evernt', event.target.checked);
    let obj = {
      id: this.profile.id,
      isNotifications: event.target.checked,
    };
    await this.authService.muteAndUnmuteNotificationsForUser(obj);
  }
  async changeSettingPrivacy(event: any) {
    let obj = {
      id: this.profile.id,
      privacySetting: event.target.checked ? 'public' : 'private',
    };
    await this.authService.changePrivacy(obj);
  }
}
