<div class="modal-body home-modal-bdy permission-req-modal row">
  <div class="home-modal-hdr col-11">
    <h4 class="modal-title">Invite Participant</h4>
  </div>
  <button type="button" class="close pull-right col-1" aria-label="Close" (click)="confirmMessge(false)">
    <img src="../../assets/x-circle.png" />
  </button>
  <div class="col-12">
    <form #emailForm="ngForm" (ngSubmit)="submit(emailForm)">
      <div class="justify-content-around mb-3">
        <div class="form-group">
          <label for="name">Email</label>
          <input
            type="text"
            class="form-control"
            name="email"
            placeholder="Email Id"
            required
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            #emailReq="ngModel"
            ngModel
          />
          <div
            *ngIf="submitted && emailReq?.invalid && (emailReq?.dirty || emailReq?.touched || emailReq?.untouched)"
            class="error-container"
          >
            Please enter valid Email Id.
          </div>
        </div>
      </div>
      <div class="home-modal-btns text-center mt-5">
        <button
          class="btn meating-cncl-btn mb-2 Close w-100"
          aria-label="Close"
          type="submit"
          [disabled]="!emailForm.valid"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
