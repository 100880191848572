export function addPermissionToRole(obj: any) {
  return `mutation{
    addPermissionToRole(organizationRoleId:${obj.organizationRoleId}
    masterPermissionId:${obj.masterPermissionId}){
      id
      organizationRoleId
      masterPermissionId
    }
  }`;
}
export function deletePermissionToRole(obj: any) {
  return `mutation{
        deletePermissionToRole(organizationRoleId:${obj.organizationRoleId}
        masterPermissionId:${obj.masterPermissionId}){
          message
        }
      }`;
}
export function createOrganizationRole(obj: any) {
  return `mutation{
      createOrganizationRole(organizationId:${obj.organizationId}
      roleName:"${obj.roleName}"
      userId:${obj.userId}){
        id
        organizationId
        roleName
      }
    }`;
}
export function deleteOrganizationRole(id: any, orgId: any) {
  return `mutation{
    deleteOrganizationRole(id:${id}
      orgId:${orgId}){
      message
    }
  }`;
}
