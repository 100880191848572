import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  subject: Subject<any>;
  data: any;
  cropperSettings: any;

  @ViewChild('cropper') cropper: ImageCropperComponent;

  constructor() {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.rounded = true;
    this.cropperSettings.canvasWidth = '730px';
    this.cropperSettings.canvasHeight = '500px';
    this.cropperSettings.centerTouchRadius = 100;
    this.data = {};
  }

  ngOnInit(): void {}
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  fileChangeListener(event: any) {
    var image: any = new Image();
    var file: File = event.target.files[0];
    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      image.name = file.name;
      that.cropper.setImage(image);
    };
    myReader.readAsDataURL(file);
  }
  save() {
    this.subject.next(this.data);
    this.subject.complete();
  }
}
