import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { AuthService } from '@app/services/auth.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  resetPassword: FormGroup;
  submitted = false;
  token: string;
  loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customValidator: CustomvalidationService,
    private toastr: ToastrService,
    private _authService: AuthService
  ) {
    this.route.params.subscribe((params) => {
      this.token = params.token;
    });
  }

  ngOnInit(): void {
    this.resetPassword = this.formBuilder.group(
      {
        newPassword: ['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: this.customValidator.MatchPassword('newPassword', 'confirmPassword'),
      }
    );
  }
  get verifyForm() {
    return this.resetPassword.controls;
  }
  changePassword() {
    this.submitted = true;
    if (!this.resetPassword.valid) {
      return;
    }
    console.log('reset', this.resetPassword.value);
    let obj = {
      token: this.token,
      password: this.resetPassword.value.confirmPassword,
    };
    this.loading = true;
    this._authService.resetPassword(obj).then((res) => {
      if (res === undefined) {
        this.loading = false;
        return;
      }
      this.loading = false;
      this.router.navigate(['/dashboard']);
    });
  }
}
