import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss'],
})
export class VerifyOtpComponent implements OnInit {
  @Output() signUp = new EventEmitter<boolean>();
  @Output() otpValid = new EventEmitter<boolean>();
  @Input() email: string;
  constructor(private _authService: AuthService) {}

  ngOnInit(): void {}
  backToSignUp() {
    this.signUp.emit(true);
  }
  verifyOtp(event: NgForm) {
    if (!event.valid) {
      return;
    }
    let finalOtp = `${event.value.A}${event.value.B}${event.value.C}${event.value.D}${event.value.E}${event.value.F}`;
    let obj = {
      email: this.email,
      otp: Number(finalOtp),
    };
    this._authService.confirmOtp(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.otpValid.emit(true);
    });
  }
  resendOtp() {
    this._authService.resendOtp(this.email);
  }
}
