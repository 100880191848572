<div class="row">
  <div class="col-md-4 col-lg-3 kinder-space-model-left d-flex justify-content-center align-items-start">
    <ul>
      <!-- <li>
                <span>1</span>
                <p>KiinderSpace Details</p>
            </li> -->
      <!-- class='success' -->
      <li class="active" #step1>
        <span>1</span>
        <p>Kiinder Space Details</p>
      </li>
      <li #step2>
        <span>2</span>
        <p>Invite Members</p>
      </li>
    </ul>
  </div>

  <div class="col-md-8 col-lg-9 kinder-space-model-right">
    <button type="button" class="close" (click)="confirmMessge(false)" aria-label="Close">
      <i class="bi bi-x-circle"></i>
    </button>
    <div class="step1-wrapper" *ngIf="step === 1">
      <div class="step-number-wrapper">Step <span>1</span>/2</div>
      <div class="kinder-space-model-content">
        <div class="kinder-space-model-content-heading">
          <h2>
            What's the name of your <br />
            organization or team?
          </h2>
          <p>
            This will be the name of your Kiinder Space or team- choose something <br />
            that's easy to recognize
          </p>
        </div>
        <form #orgForm="ngForm" (ngSubmit)="nextPage(orgForm)">
          <div class="setup-profile-form">
            <div class="form-group">
              <label>Organization or Team</label>
              <input
                type="text"
                class="form-control"
                name="organizationName"
                placeholder=""
                required
                [(ngModel)]="step1From.organizationName"
                autocomplete="off"
              />
            </div>
            <label>Upload Logo</label>
            <div class="setup-profile-avatar">
              <img class="avatar" src="{{ logo ? logo : '../../assets/organizationAvtar.png' }}" alt="" />
              <div class="PicLoader text-center" *ngIf="picLoading">
                <span class="spinner-border spinner-border-sm mr-1 text-center"></span>
              </div>
              <label class="edit-profile-pic anc-pointer" (click)="showCropper()"
                ><img src="../../assets/profile-edit.svg" alt=""
              /></label>
            </div>
            <div class="continue-btn">
              <button type="submit" class="btn" id="firststep" [disabled]="!orgForm.valid">Next</button>
              <a class="skipstep" (click)="confirmMessge(false)">Skip this step</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="step2-wrapper" *ngIf="step === 2">
      <a (click)="stepMove(1)" class="back-step-btn">
        <i class="bi bi-arrow-left-short"></i>
      </a>
      <div class="step-number-wrapper">Step <span>2</span>/2</div>
      <div class="kinder-space-model-content">
        <div class="kinder-space-model-content-heading">
          <h2>
            Who would you like to invite <br />
            to "{{ step1From.organizationName }}"
          </h2>
          <p>
            This will be the name of your Kiinder organization or team- choose <br />
            something that's easy to recognize
          </p>
        </div>
        <form>
          <div class="setup-profile-form">
            <div class="form-group">
              <input class="form-control bg-gray" type="text" name="copy" [(ngModel)]="orgUrl" #userinput readonly />
              <img
                class="copyImg"
                src="../../assets/copy.png"
                style="cursor: pointer"
                (click)="copyInputMessage(userinput)"
              />
            </div>

            <div class="form-group">
              <span class="lockIcon"><img src="../../assets/lock.svg" alt="" /></span>
              <select
                class="form-control"
                id="selectControl"
                [(ngModel)]="step1From.privacy"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="public">Anyone with this link can join</option>
                <option value="private">Only invited people can join</option>
              </select>
            </div>
            <div class="form-group">
              <label>Invite Members</label>
              <input
                type="text"
                class="form-control"
                name="inviteMember"
                placeholder="Enter email Id"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                [(ngModel)]="userSelectsString"
                placeholder="Email"
                #suggestion="ngModel"
                (keyup.enter)="inviteMember(suggestion)"
              />
              <div
                *ngIf="
                  submitted &&
                  suggestion?.invalid &&
                  (suggestion?.dirty || suggestion?.touched || suggestion?.untouched)
                "
                class="error-container"
              >
                Valid email is required.
              </div>
            </div>
            <div class="invite-members-listing d-flex flex-wrap">
              <div class="invite-members-wrap" *ngFor="let user of userSelects; index as i">
                <figure>
                  <img
                    src="{{ user.profilePicUrl ? user.profilePicUrl : '../../assets/male_avatar-512.svg' }}"
                    alt=""
                  />
                </figure>
                <p *ngIf="user.name">{{ user.name | titlecase }}</p>
                <p *ngIf="!user.name">{{ user.email }}</p>
                <i class="bi bi-x" (click)="deleteSelects(i)"></i>
              </div>
            </div>
            <div class="continue-btn">
              <button type="button" class="btn" (click)="createOrganization()">
                <span *ngIf="loading" class="spinner-border mr-3" style="color: white; height: 18px; width: 18px"></span
                >Submit
              </button>
              <a class="skipstep" (click)="skipStep()">Skip this step</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
