<header>
  <nav class="navbar navbar-expand-lg navbar-light customNavbar fronHeader">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="../../../assets/logo.svg" />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        aria-controls="navbar-menu"
        aria-label="Toggle navigation"
        (click)="toggleMenu()"
        [attr.aria-expanded]="!menuHidden"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbar-menu" class="collapse navbar-collapse customColl" [ngbCollapse]="menuHidden">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" (click)="logIn('logIn')">Sign In</a>
          </li>
          <button class="btn skyblueBtn frontHeaderBtn" type="button" (click)="logIn('signUp')">
            Sign Up for Free
          </button>
        </ul>
      </div>
    </div>
  </nav>
</header>
