import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { OrganizationService } from '@app/services/organization.service';
import { RandomIdService } from '@app/services/random-id.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CropperComponent } from '../image-cropper/cropper.component';

@Component({
  selector: 'app-kinder-spacing',
  templateUrl: './kinder-spacing.component.html',
  styleUrls: ['./kinder-spacing.component.scss'],
})
export class KinderSpacingComponent implements OnInit {
  subject: Subject<boolean>;
  croperModalRef: BsModalRef;
  @ViewChild('step1', { static: false }) step1: ElementRef;
  @ViewChild('step2', { static: false }) step2: ElementRef;
  profile: any;
  step = 1;
  step1From = {
    userId: 0,
    organizationName: '',
    logo: '',
    manualId: '',
    privacy: 'public',
  };
  loading = false;
  orgUrl: any;
  submitted = false;
  userSelects: any = [];
  userSelectsString = '';
  picLoading = false;
  logo = '';
  constructor(
    private renderer: Renderer2,
    private _randomIdService: RandomIdService,
    private _organizationService: OrganizationService,
    private toastr: ToastrService,
    private router: Router,
    private _auth: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.profile = this.profile = this._auth.getProfileFromToken();
    this.step1From.userId = this.profile.id;
    this.step1From.manualId = this._randomIdService.randomNumber();
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  stepMove(step: number) {
    this.step = step;
    if (step === 1) {
      this.renderer.addClass(this.step1.nativeElement, 'active');
      this.renderer.removeClass(this.step2.nativeElement, 'active');
    }
    if (step === 2) {
      this.renderer.removeClass(this.step1.nativeElement, 'active');
      this.renderer.addClass(this.step1.nativeElement, 'success');

      this.renderer.addClass(this.step2.nativeElement, 'active');
    }
  }
  nextPage(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.orgUrl = `https://organization.kiinder.com/${this.step1From.manualId}`;
    this.stepMove(2);
  }
  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('LINK COPIED SUCCESSFULLY', 'Success');
  }
  async inviteMember(form: any) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    if (form.value) {
      if (this.userSelectsString === this.profile.email) {
        this.toastr.error('You cannot add yourself to it', 'Error');
        return;
      }

      if (this.userSelectsString === this.profile.email) {
        this.toastr.error('You cannot add yourself to it', 'Error');
        return;
      }
      let unique =
        this.userSelects.length > 0 && this.userSelects.find((item: any) => item.email === this.userSelectsString);

      if (unique) {
        this.toastr.error('Already added', 'Error');
        this.submitted = false;
        return;
      } else {
        let user = await this._auth.getUserByEmailId(this.userSelectsString);
        if (user) {
          this.userSelects.push({
            email: user.email,
            name: user.fullName,
            profilePicUrl: user.profilePicUrl,
          });
          this.userSelectsString = '';
          this.submitted = false;
        } else {
          this.userSelects.push({
            email: this.userSelectsString,
            name: '',
            profilePicUrl: '',
          });
          this.userSelectsString = '';
          this.submitted = false;
        }
      }
    }
  }
  deleteSelects(s: any) {
    this.userSelects = this.userSelects.filter((item: any, index: Number) => index !== s);
  }
  createOrganization() {
    if (this.userSelects.length === 0) {
      this.toastr.error('Please add atleast one member to organization otherwise skip this step', 'Error');
      return;
    }
    this.loading = true;
    this._organizationService.createOrganization(this.step1From).then((res) => {
      if (res === undefined) {
        return;
      }
      let member = {
        organizationId: res.data.data.createOrganization.id,
        emails: this.userSelects,
        userId: this.profile.id,
      };
      this.toastr.success(`${this.step1From.organizationName} created successfully`, 'Success');
      this._organizationService.inviteUserToOrganization(member).then((res) => {
        this.loading = false;
        this.router.navigate(['/landing/contacts']);
        this.confirmMessge(true);
      });
    });
  }
  skipStep() {
    this._organizationService.createOrganization(this.step1From).then((res) => {
      if (res === undefined) {
        return;
      }
      this.toastr.success(`${this.step1From.organizationName} created successfully`, 'Success');
      this.router.navigate(['/landing/contacts']);
      this.confirmMessge(true);
    });
  }
  async handleFileInput(file: any) {
    this.picLoading = true;
    const formData = new FormData();
    let operations: any = {
      query: `
        mutation($file: Upload!) {
          uploadFile(file: $file) {
            Key
            Location
          }
        }
      `,
      variables: {
        file: null,
      },
    };
    let _map = {
      file: ['variables.file'],
    };
    var fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file);
    this._auth.uploadImage(fd).then((res: any) => {
      if (res.data.errors) {
        this.picLoading = false;

        res.data.errors &&
          res.data.errors.map((err: any) => {
            this.toastr.error(err.message, 'Error');
          });
        return;
      }
      this.logo = res.data.data.uploadFile.Location;
      this.step1From.logo = this.logo;
      this.picLoading = false;
    });
  }
  showCropper() {
    const subject = new Subject<any>();
    this.croperModalRef = this.modalService.show(CropperComponent, {
      class: 'modal-md-invitePeople modal-dialog-centered',
    });
    this.croperModalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data) {
        let file = this.dataURLtoFile(data.image, data.original.name);
        this.handleFileInput(file);
        this.croperModalRef.hide();
      } else {
        this.croperModalRef.hide();
      }
    });
  }
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
