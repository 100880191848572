<!-- <div class="setup-profile-header py-2 px-3">
    <img class="home-gray" src="../../../assets/logo.png" />
</div> -->
<div class="container">
  <div class="row">
    <div class="col-md-8 mx-auto">
      <div class="text-center logo-div">
        <img class="home-gray" src="../../../assets/logo.png" />
      </div>
      <div class="heading-form">
        <h3>Reset Password</h3>
      </div>

      <form [formGroup]="resetPassword" class="form-padding" (ngSubmit)="changePassword()">
        <label for="newPassword" class="otp-lable">New Password</label>
        <div class="input-group">
          <input
            type="password"
            formControlName="newPassword"
            class="form-control"
            required
            placeholder="New Password"
            [ngClass]="{ 'is-invalid': submitted && verifyForm.newPassword.errors }"
          />
        </div>
        <div *ngIf="submitted && verifyForm.newPassword.errors" class="text-danger errorMsg">
          <div *ngIf="verifyForm.newPassword.errors.required">New Password is required.</div>
        </div>
        <span
          class="text-danger errorMsg"
          *ngIf="verifyForm.newPassword.touched && verifyForm.newPassword.errors?.invalidPassword"
        >
          Password should have minimum 8 characters, at least 1 uppercase letter and 1 special character.
        </span>

        <label for="confirmPassword" class="otp-lable mt-2">Confirm Password</label>
        <div class="input-group">
          <input
            type="password"
            formControlName="confirmPassword"
            class="form-control w-100"
            required
            placeholder="Confirm Password"
            [ngClass]="{ 'is-invalid': submitted && verifyForm.confirmPassword.errors }"
          />
          <span
            class="text-danger errorMsg"
            *ngIf="verifyForm.confirmPassword.touched && verifyForm.confirmPassword.errors?.passwordMismatch"
          >
            Passwords does not match
          </span>
        </div>
        <div *ngIf="submitted && verifyForm.confirmPassword.errors" class="text-danger errorMsg">
          <div *ngIf="verifyForm.confirmPassword.errors.required">Confirm Password is required.</div>
        </div>
        <button type="submit" class="btn resetBtn">
          <span
            *ngIf="loading"
            class="spinner-border spinner-border-sm mr-1"
            style="color: white; margin-bottom: 5px"
          ></span>
          Change Password
        </button>
      </form>
    </div>
  </div>
</div>
