export function getVideoToken(userName: string) {
  return `
  query{
    getTwilioVideoToken(username:"${userName}"){
    token
  }}`;
}
export function createRoom(roomName: any) {
  return `
  mutation{createVideoRoom(roomName:"${roomName}"){
    roomSid
    meetingId
  }}`;
}
export function getTwilioVideoRooms() {
  return `
  query{getTwilioVideoRooms(allRooms:"sdfcsd"){
    rooms{
      uniqueName
      sid
      dateCreated
      dateUpdated
      maxParticipants
    }
  }}`;
}
