<app-black-header></app-black-header>

<div class="main-landing-wrapper d-flex flex-wrap">
  <div class="main-landing-left-bar">
    <app-organization-landing></app-organization-landing>
  </div>
  <div class="main-landing-right-bar">
    <router-outlet></router-outlet>
  </div>
</div>
