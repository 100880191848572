import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { MeetingService } from '../services/meeting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-leave-kinderspace',
  templateUrl: './leave-kinderspace.component.html',
  styleUrls: ['./leave-kinderspace.component.scss'],
})
export class LeaveKinderspaceComponent implements OnInit {
  subject: Subject<boolean>;
  meetingId: any;
  isSubmitted = false;
  name = '';
  submitted = false;
  constructor(private _meetingService: MeetingService, private toastr: ToastrService) {}

  ngOnInit(): void {}
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
}
