<div class="landing-page-title">
  <h2>Invites</h2>
</div>

<div class="landing-page-container">
  <div *ngIf="loading" class="text-center"><span class="spinner-border spinner-border-sm mr-1"></span></div>
  <h4 class="noInvite" *ngIf="invitations.length == 0 && !loading">No Invite found</h4>
  <div class="kinder-spaces-wrapper">
    <h4 *ngIf="invitations.length > 0" class="pending-heading">Pending- {{ invitations.length }}</h4>
    <div
      class="kinder-space-wrap d-flex align-items-center justify-content-between"
      *ngFor="let invitation of invitations; index as i"
    >
      <div class="kinder-space-info d-flex align-items-center">
        <span class="kinder-space-number">
          <img
            src="{{
              invitation.Organization.logo
                ? invitation.Organization.logo
                : '../../../../../assets/organizationAvtar.png'
            }}"
            alt=""
          />
        </span>
        <div class="kinder-space-info-content">
          <h3>{{ invitation.Organization.organizationName | titlecase }}</h3>
          <!-- <p>username@kiinder</p> -->
        </div>
      </div>
      <div class="kinder-space-accept-reject d-flex align-items-center">
        <button class="btn btn-accept" (click)="userAcceptInvitation(invitation.organizationId, invitation.email)">
          <i class="bi bi-check2"></i>Accept
        </button>
        <button class="btn text-danger" (click)="userRejectInvitaion(invitation.organizationId, invitation.email)">
          <i class="bi bi-x"></i>Reject
        </button>
      </div>
    </div>
  </div>
</div>
