import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '@app/services/auth.service';
import { emailRegex } from '../../helpers/index';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @Output() id = new EventEmitter<string>();
  @Output() pass = new EventEmitter<boolean>();
  error: string | undefined;
  signupForm!: FormGroup;
  modalRef: BsModalRef;
  loading = false;
  isSubmitted = false;
  subject: Subject<boolean>;
  isSignUp = true;
  emailId: string;
  years: any = [];
  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  days: any = [];
  show: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,

    private modalService: BsModalService
  ) {
    this.createForm();
    // this.getYears(1967);
  }

  ngOnInit(): void {}

  private createForm() {
    this.signupForm = this.formBuilder.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      // year: ['', Validators.required],
      // month: ['', Validators.required],
      // day: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

  // private getYears(startYear: any) {
  //   var currentYear = new Date().getFullYear(),
  //     years = [];
  //   startYear = startYear || 1980;
  //   while (startYear <= currentYear) {
  //     this.years.push(startYear++);
  //   }
  // }

  // changeYear(event: any) {
  //   this.monthNames = [];
  //   let currentYear = new Date().getFullYear();
  //   let currentMonth = new Date().getMonth();
  //   if (Number(event.target.value) === currentYear) {
  //     for (var i = 0; i < currentMonth + 1; i++) {
  //       this.monthNames.push(new Date(event.target.value, i).toLocaleString('en', { month: 'long' }));
  //     }
  //   } else {
  //     for (var i = 0; i < 12; i++) {
  //       this.monthNames.push(new Date(event.target.value, i).toLocaleString('en', { month: 'long' }));
  //     }
  //   }
  //   // this.signupForm.setValue({ "month": "" })
  //   if (this.signupForm.value && this.signupForm.value.year && this.signupForm.value.month) {
  //     this.getDaysInMonth(this.getMonthNumber(this.signupForm.value.month), Number(this.signupForm.value.year));
  //   }
  // }
  showPass() {
    this.show = !this.show;
  }
  // changeMonth(event: any) {
  //   if (this.signupForm.value && this.signupForm.value.year && this.signupForm.value.month) {
  //     this.getDaysInMonth(this.getMonthNumber(this.signupForm.value.month), Number(this.signupForm.value.year));
  //   }
  // }

  // getMonthNumber(month: string) {
  //   var monthNumber = new Array();
  //   monthNumber[0] = 'January';
  //   monthNumber[1] = 'February';
  //   monthNumber[2] = 'March';
  //   monthNumber[3] = 'April';
  //   monthNumber[4] = 'May';
  //   monthNumber[5] = 'June';
  //   monthNumber[6] = 'July';
  //   monthNumber[7] = 'August';
  //   monthNumber[8] = 'September';
  //   monthNumber[9] = 'October';
  //   monthNumber[10] = 'November';
  //   monthNumber[11] = 'December';
  //   return monthNumber.indexOf(month);
  // }

  // getDaysInMonth(month: any, year: any) {
  //   this.days = [];
  //   let currentYear = new Date().getFullYear();
  //   let currentDate = new Date().getDate();
  //   var date = new Date(year, month, 1);
  //   if (currentYear === year) {
  //     for (var i = 1; i <= currentDate; i++) {
  //       this.days.push(i);
  //     }
  //   } else {
  //     while (date.getMonth() === month) {
  //       this.days.push(new Date(date).getDate());
  //       date.setDate(date.getDate() + 1);
  //     }
  //   }
  // }

  get f() {
    return this.signupForm.controls;
  }

  submitForm() {
    this.isSubmitted = true;
    if (!this.signupForm.valid) {
      return;
    }
    let formValue = this.signupForm.value;
    // formValue['monthNo'] = this.getMonthNumber(this.signupForm.value.month) + 1;
    this.loading = true;
    this.authService.register(formValue).then((response) => {
      if (response == undefined) {
        this.loading = false;
        return;
      } else {
        this.loading = false;
        this.isSignUp = false;
        this.emailId = this.signupForm.value.email;
      }
    });
  }
  logIn(id: any) {
    this.id.emit(id);
  }
  change(bol: boolean) {
    this.isSignUp = bol;
  }
  otpValid(valid: boolean) {
    this.pass.emit(valid);
  }
}
