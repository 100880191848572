<section class="leftMeetingCover">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="leftMeetingDetailCover">
          <div class="leftMeetingDetailSubCover">
            <h2 class="leftMeetingTitle">You have left the meeting</h2>
            <div class="leftMeetingBtnCover">
              <button type="button" class="btn skyblueBtn cancelBtn" (click)="cancel()">Cancel</button>
              <button type="button" class="btn skyblueBtn rejoinBtn" (click)="reJoin()">Rejoin</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
