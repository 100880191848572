import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { WithoutLoginUrls } from '../helpers/index';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class Authorization implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.getProfileFromToken();
    if (currentUser) {
      if (this.authService.isTokenExpired()) {
        this.authService.logout();
        this.router.navigate(['/dashboard']);
      }
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
        // role not authorised so redirect to home page
        this.router.navigate(['']);
        return false;
      }

      // authorised so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/dashboard'], { queryParams: { returnUrl: state.url } });
    this.router.navigate(['/dashboard']);
    return false;
  }
}
