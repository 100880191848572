<div class="setup-profile-header py-2 px-3">
  <img class="home-gray" src="../../../assets/logo.png" />
</div>
<div class="setupProfile container-fluid">
  <div class="row setupProfile-wrapper align-items-start">
    <div class="col-lg-4 setupProfile-navigation d-flex justify-content-center">
      <ul>
        <li class="active" #personal>
          <span>1</span>
          <p>Personal Information</p>
        </li>
        <li #preferences>
          <span>2</span>
          <p>Preferences</p>
        </li>
        <li #address>
          <span>3</span>
          <p>Address</p>
        </li>
      </ul>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
      <form class="form-padding" #onBoardingForm="ngForm" (ngSubmit)="submitForm(onBoardingForm)">
        <div class="setup-profile-content">
          <div class="setup-profile-form text-left">
            <h2>1. Personal Information</h2>
            <div class="setup-profile-avatar">
              <img
                class="avatar"
                src="{{ profilePicUrl ? profilePicUrl : '../../assets/avater-profile.png' }}"
                alt=""
              />
              <div class="PicLoader text-center">
                <span *ngIf="picLoading" class="spinner-border spinner-border-sm mr-1 text-center"></span>
              </div>
              <label class="edit-profile-pic anc-pointer" (click)="showCropper()"
                ><img src="../../assets/profile-edit.svg" alt=""
              /></label>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstName">First Name</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="onBoarding.firstName"
                    name="firstName"
                    disabled
                    required
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="lastName">Last Name</label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="onBoarding.lastName"
                    name="lastName"
                    disabled
                    required
                  />
                </div>
              </div>
            </div>
            <div class="form-group m-0">
              <label for="exampleInputEmail1">Gender</label>
            </div>

            <div class="form-row">
              <div class="form-group">
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio1"
                    value="Male"
                    name="gender"
                    required
                    [(ngModel)]="onBoarding.gender"
                  />
                  <label class="form-check-label" for="inlineRadio1">Male</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="inlineRadio2"
                    value="Female"
                    required
                    [(ngModel)]="onBoarding.gender"
                    name="gender"
                  />
                  <label class="form-check-label" for="inlineRadio2">Female</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="firstLanguage">First Language</label>
                  <select
                    class="form-control"
                    id="firstLanguage"
                    [(ngModel)]="onBoarding.firstLanguage"
                    #firstLanguage="ngModel"
                    required
                    name="firstLanguage"
                  >
                    <option value="">Select</option>
                    <option value="French">French</option>
                    <option value="English">English</option>
                    <option value="German">German</option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      firstLanguage?.invalid &&
                      (firstLanguage?.dirty || firstLanguage?.touched || firstLanguage?.untouched)
                    "
                    class="error-container"
                  >
                    First Language is required.
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="otherLanguage">Other Language</label>
                  <select
                    class="form-control"
                    id="otherLanguage"
                    [(ngModel)]="onBoarding.otherLanguage"
                    #otherLanguage="ngModel"
                    name="otherLanguage"
                  >
                    <option value="">None</option>
                    <option value="French">French</option>
                    <option value="English">English</option>
                    <option value="German">German</option>
                  </select>
                  <div
                    *ngIf="
                      submitted &&
                      otherLanguage?.invalid &&
                      (otherLanguage?.dirty || otherLanguage?.touched || otherLanguage?.untouched)
                    "
                    class="error-container"
                  >
                    Other Language is required.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="setup-profile-form text-left my-4">
            <h2>2. Preferences</h2>
            <div class="form-group">
              <label>Privacy setting</label>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-check-parent">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="exampleRadios1"
                        value="private"
                        name="privacySetting"
                        [(ngModel)]="onBoarding.privacySetting"
                      />
                      <label class="form-check-label mr-2" for="exampleRadios1">
                        <p class="m-0">Private</p>
                        <small>Lorem ipsum is set of dolor</small>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-check-parent">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        id="exampleRadios2"
                        [(ngModel)]="onBoarding.privacySetting"
                        name="privacySetting"
                        value="public"
                      />
                      <label class="form-check-label mr-2" for="exampleRadios2">
                        <p class="m-0">Public</p>
                        <small>Lorem ipsum is set of dolor</small>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="inputState">Contact Preferences</label>
              <select
                id="inputState"
                class="form-control"
                [(ngModel)]="onBoarding.contactPreferences"
                #contactPreferences="ngModel"
                required
                name="contactPreferences"
              >
                <option>Email</option>
                <option>Gmail</option>
              </select>
              <div
                *ngIf="
                  submitted &&
                  contactPreferences?.invalid &&
                  (contactPreferences?.dirty || contactPreferences?.touched || contactPreferences?.untouched)
                "
                class="error-container"
              >
                Contact Preferences is required.
              </div>
            </div>
          </div>
          <div class="setup-profile-form text-left my-4">
            <h2>3. Address</h2>
            <div class="form-group">
              <label for="address">Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Add Address"
                required
                [(ngModel)]="onBoarding.presonalAddress.address"
                name="address"
                #perAddress="ngModel"
              />
              <div
                *ngIf="
                  submitted &&
                  perAddress?.invalid &&
                  (perAddress?.dirty || perAddress?.touched || perAddress?.untouched)
                "
                class="error-container"
              >
                Address is required.
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputEmail1">Country</label>
              <select
                class="form-control"
                [(ngModel)]="onBoarding.presonalAddress.country"
                id="country"
                name="country"
                #country="ngModel"
                required
                (change)="getAllSatate(country)"
              >
                <option *ngFor="let country of countriesList" [ngValue]="country.country">
                  {{ country.country }}
                </option>
              </select>
              <div
                *ngIf="submitted && country?.invalid && (country?.dirty || country?.touched || country?.untouched)"
                class="error-container"
              >
                Country is required.
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputState">State</label>
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="onBoarding.presonalAddress.state"
                  #state="ngModel"
                  required
                  name="state"
                >
                  <option *ngFor="let state of states" [ngValue]="state">
                    {{ state }}
                  </option>
                </select>
                <div
                  *ngIf="submitted && state?.invalid && (state?.dirty || state?.touched || state?.untouched)"
                  class="error-container"
                >
                  State is required.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="postal_code">Pincode</label>
                <input
                  type="text"
                  id="postal_code"
                  class="form-control"
                  [(ngModel)]="onBoarding.presonalAddress.postal_code"
                  name="postal_code"
                  #postal_code="ngModel"
                  required
                />
                <div
                  *ngIf="
                    submitted &&
                    postal_code?.invalid &&
                    (postal_code?.dirty || postal_code?.touched || postal_code?.untouched)
                  "
                  class="error-container"
                >
                  Pincode is required.
                </div>
              </div>
            </div>
            <h4>Billing Address</h4>
            <div class="form-group">
              <div class="form-check custom-checkbox same-billing-address">
                <input class="form-check-input" type="checkbox" id="gridCheck" (change)="sameAsAbove(check)" #check />
                <label class="form-check-label" for="gridCheck"> Same as above </label>
                <!-- <input
					type="checkbox"
					id="gridCheck"
					value="check.name"
					ng-model="check.checked"
					(change)="sameAsAbove(check)"
					#check
					class="hidden"
					/>

					<i
					class="glyphicon"
					ng-class="{ 'glyphicon-check': check.checked, 'glyphicon-unchecked': !check.checked }"
					ng-click="check.checked = !check.checked"
					></i>

					<label ng-bind="check.name" ng-click="check.checked = !check.checked">Same as above</label> -->
              </div>
            </div>

            <div class="form-group">
              <label>Address</label>
              <input
                type="text"
                class="form-control"
                placeholder="Add Address"
                [(ngModel)]="onBoarding.billingAddress.address"
                name="bill-address"
                [disabled]="isBillingAddress"
                #billAddress="ngModel"
                required
              />
              <div
                *ngIf="
                  submitted &&
                  billAddress?.invalid &&
                  (billAddress?.dirty || billAddress?.touched || billAddress?.untouched)
                "
                class="error-container"
              >
                Address is required.
              </div>
            </div>

            <div class="form-group">
              <label>Country</label>
              <select
                class="form-control"
                [(ngModel)]="onBoarding.billingAddress.country"
                id="country"
                #billCountry="ngModel"
                required
                name="bill-country"
                [disabled]="isBillingAddress"
                (change)="getAllSatate(billCountry)"
              >
                <option *ngFor="let country of countriesList" [ngValue]="country.country">
                  {{ country.country }}
                </option>
              </select>
              <div
                *ngIf="
                  submitted &&
                  billCountry?.invalid &&
                  (billCountry?.dirty || billCountry?.touched || billCountry?.untouched)
                "
                class="error-container"
              >
                Country is required.
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputState">State</label>
                <select
                  id="inputState"
                  class="form-control"
                  [(ngModel)]="onBoarding.billingAddress.state"
                  #billState="ngModel"
                  required
                  name="bill-state"
                  [disabled]="isBillingAddress"
                >
                  <option *ngFor="let state of states" [ngValue]="state">
                    {{ state }}
                  </option>
                </select>
                <div
                  *ngIf="
                    submitted && billState?.invalid && (billState?.dirty || billState?.touched || billState?.untouched)
                  "
                  class="error-container"
                >
                  State is required.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="postal_code">Pincode</label>
                <input
                  type="text"
                  id="postal_code"
                  class="form-control"
                  [(ngModel)]="onBoarding.billingAddress.postal_code"
                  name="bill-postal_code"
                  #billPincode="ngModel"
                  required
                  [disabled]="isBillingAddress"
                />
                <div
                  *ngIf="
                    submitted &&
                    billPincode?.invalid &&
                    (billPincode?.dirty || billPincode?.touched || billPincode?.untouched)
                  "
                  class="error-container"
                >
                  Pincode is required.
                </div>
              </div>
            </div>
          </div>

          <div class="continue-btn">
            <button class="btn" type="submit">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
                style="color: white; margin-bottom: 5px"
              ></span
              >Continue
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
