import axios from 'axios';
import { environment } from '../../environments/environment';
export function axiosPost(query: any) {
  const axiosGitHubGraphQL = axios.create({
    baseURL: environment.apiUrl,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return axiosGitHubGraphQL.post('', { query: query });
}
export function axiosUploadFile(fromData: any) {
  return axios.post(environment.apiUrl, fromData);
}
