import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { BroadcastService } from '@app/services/broadcast.service';
import { OrganizationService } from '@app/services/organization.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CropperComponent } from '../image-cropper/cropper.component';

@Component({
  selector: 'app-org-setting',
  templateUrl: './org-setting.component.html',
  styleUrls: ['./org-setting.component.scss'],
})
export class OrgSettingComponent implements OnInit {
  croperModalRef: BsModalRef;
  subject: Subject<any>;
  loading = false;
  submitted = false;
  id: any;
  obj = {
    id: '',
    logo: '',
    organizationName: '',
  };
  picLoading = false;
  logo = '../../assets/organizationAvtar.png';

  constructor(
    private _organizationService: OrganizationService,
    private _broadcastService: BroadcastService,
    private _auth: AuthService,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.getOrganizationDetailById();
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  getOrganizationDetailById() {
    this._organizationService.getOrganizationDetailById(this.id).then((res) => {
      console.log('get detail by id', res);
      if (res === undefined) {
        return;
      }
      this.poluteValues(res.data.data.getOrganizationDetailById);
    });
  }
  poluteValues(obj: any) {
    this.logo = obj.logo ? obj.logo : '../../assets/organizationAvtar.png';
    console.log('objec.log', this.logo);
    this.obj = {
      id: obj.id,
      logo: obj.logo,
      organizationName: obj.organizationName,
    };
  }
  updateOrganization(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this._organizationService.updateOrganization(this.obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.confirmMessge(true);
      this._broadcastService.sendMessage({ type: 'updateOrg', message: true });
    });
  }
  async handleFileInput(file: any) {
    this.picLoading = true;

    console.log('fule', file);
    let operations: any = {
      query: `
        mutation($file: Upload!) {
          uploadFile(file: $file) {
            Key
            Location
          }
        }
      `,
      variables: {
        file: null,
      },
    };
    let _map = {
      file: ['variables.file'],
    };
    var fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file);
    this._auth.uploadImage(fd).then((res: any) => {
      if (res.data.errors) {
        this.picLoading = false;

        res.data.errors &&
          res.data.errors.map((err: any) => {
            this.toastr.error(err.message, 'Error');
          });
        return;
      }
      this.logo = res.data.data.uploadFile.Location;
      this.obj.logo = this.logo;
      this.picLoading = false;
    });
  }
  showCropper() {
    const subject = new Subject<any>();
    this.croperModalRef = this.modalService.show(CropperComponent, {
      class: 'modal-md-invitePeople modal-dialog-centered',
    });
    this.croperModalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      console.log('image', data.name);
      if (data) {
        let file = this.dataURLtoFile(data.image, data.original.name);
        this.handleFileInput(file);
        this.croperModalRef.hide();
      } else {
        this.croperModalRef.hide();
      }
    });
  }
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
