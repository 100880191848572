<div class="add-contact-popup-wrapper">
  <div class="add-contact-popup-head d-flex justify-content-between">
    <h3>{{ modalLabel }}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="confirmMessge(false)">
      <i class="bi bi-x-circle"></i>
    </button>
  </div>
  <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)">
    <div class="search-group">
      <label for="">Fullname</label>
      <input
        type="text"
        placeholder="Full Name"
        name="fullName"
        class="form-control"
        #fullName="ngModel"
        [(ngModel)]="editObj.fullName"
        ngModel
        required
      />
      <div
        *ngIf="submitted && fullName?.invalid && (fullName?.dirty || fullName?.touched || fullName?.untouched)"
        class="error-container"
      >
        Name is required.
      </div>
    </div>
    <div class="search-group">
      <label for="">Phone</label>
      <input
        type="number"
        placeholder="Phone Number"
        name="phone"
        [(ngModel)]="editObj.phone"
        class="form-control"
        #phone="ngModel"
        required
        ngModel
      />
      <div
        *ngIf="submitted && phone?.invalid && (phone?.dirty || phone?.touched || phone?.untouched)"
        class="error-container"
      >
        Phone Number is required.
      </div>
    </div>
    <div class="search-group">
      <label for="">Email</label>
      <input
        type="text"
        placeholder="Email"
        name="email"
        [(ngModel)]="editObj.email"
        class="form-control"
        #email="ngModel"
        required
        ngModel
        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
      />
      <div
        *ngIf="submitted && email?.invalid && (email?.dirty || email?.touched || email?.untouched)"
        class="error-container"
      >
        Valid email is required.
      </div>
    </div>
    <div class="search-group mt-0">
      <button class="btn add-button">{{ buttonLabel }}</button>
    </div>
    <div class="search-group mt-0">
      <button class="btn cancelBtn" (click)="confirmMessge(false)">Cancel</button>
    </div>
  </form>
  <!-- <div class="add-contact-features">
    <div class="add-contact-feature-wrap d-flex align-items-center flex-wrap">
      <span><img src="../../assets/invite-kinder.svg" alt="" /></span>
      <div class="add-contact-feature-content">
        <h4>Invite to Kiinder</h4>
        <p>Invite friends to Kiinder via sms, email</p>
      </div>
    </div>
    <div class="add-contact-feature-wrap d-flex align-items-center flex-wrap">
      <span><img src="../../assets/copy.svg" alt="" /></span>
      <div class="add-contact-feature-content">
        <h4>Copy to Clipboard</h4>
        <p>https://KiinderSpace.kiinder.com/duj-qifd-czy</p>
      </div>
    </div>
  </div> -->
</div>
