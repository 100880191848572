<div class="user-setting-modal-wrapper row">
  <div class="col-md-4 user-setting-modal-left">
    <h2>Settings</h2>
    <ul class="nav nav-pills setting-bar">
      <li class="nav-item" (click)="changeTab('profile')">
        <a class="nav-link active">Profile</a>
      </li>
      <li class="nav-item" (click)="changeTab('privacy')">
        <a class="nav-link">Notification and Privacy</a>
      </li>
      <!-- <li class="nav-item" (click)="changeTab('audioAndVideo')">
        <a class="nav-link">Audio and Video</a>
      </li> -->
      <li class="nav-item" (click)="changeTab('changePassword')">
        <a class="nav-link">Change Password</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-danger" (click)="logout()">Log Out</a>
      </li>
    </ul>
  </div>
  <div class="col-md-8 user-setting-modal-right">
    <button type="button" class="close" (click)="confirmMessge(false)">
      <i class="bi bi-x-circle"></i>
    </button>
    <ul class="tab-content setting-bar-right">
      <li class="tab-pane fade show active" id="profile">
        <form #editForm="ngForm" (ngSubmit)="updateProfile(editForm)">
          <div class="tab-pane-heading">
            <h2 class="title">Edit Profile</h2>
            <div class="setup-profile-avatar">
              <img
                class="avatar"
                src="{{ profilePicUrl ? profilePicUrl : '../../assets/avater-profile.png' }}"
                alt=""
              />
              <div class="PicLoader text-center" *ngIf="picLoading">
                <span class="spinner-border spinner-border-sm mr-1 text-center"></span>
              </div>

              <label class="edit-profile-pic anc-pointer" (click)="showCropper()"
                ><img src="../../assets/profile-edit.svg" alt=""
              /></label>
            </div>
          </div>
          <div class="user-setting-form-wrapper">
            <div class="row">
              <div class="form-group col-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  name="firstName"
                  [(ngModel)]="userProfile.firstName"
                  required
                  #firstName="ngModel"
                />
                <div
                  *ngIf="
                    submitted && firstName?.invalid && (firstName?.dirty || firstName?.touched || firstName?.untouched)
                  "
                  class="error-container"
                >
                  First Name is required.
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Last Name"
                  name="lastName"
                  [(ngModel)]="userProfile.lastName"
                  required
                  #lastName="ngModel"
                />
                <div
                  *ngIf="
                    submitted && lastName?.invalid && (lastName?.dirty || lastName?.touched || lastName?.untouched)
                  "
                  class="error-container"
                >
                  Last Name is required.
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Gender</label>
              <div class="gender-radio d-flex align-items-center">
                <div class="custom-radio">
                  <input
                    type="radio"
                    id="Male"
                    name="gender"
                    checked
                    value="Male"
                    [(ngModel)]="userProfile.gender"
                    required
                  />
                  <label for="Male">Male</label>
                </div>
                <div class="custom-radio">
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    value="Female"
                    [(ngModel)]="userProfile.gender"
                    required
                  />
                  <label for="female">Female</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>First Language</label>
              <select
                class="form-control"
                name="firstLanguage"
                [(ngModel)]="userProfile.firstLanguage"
                required
                #firstLanguage="ngModel"
              >
                <option value="">Select</option>
                <option value="French">French</option>
                <option value="English">English</option>
                <option value="German">German</option>
              </select>
              <div
                *ngIf="
                  submitted &&
                  firstLanguage?.invalid &&
                  (firstLanguage?.dirty || firstLanguage?.touched || firstLanguage?.untouched)
                "
                class="error-container"
              >
                First Language is required.
              </div>
            </div>
            <div class="form-group">
              <label>Other Language</label>
              <select class="form-control" name="otherLanguage" [(ngModel)]="userProfile.otherLanguage">
                <option value="">Select</option>
                <option value="French">French</option>
                <option value="English">English</option>
                <option value="German">German</option>
              </select>
            </div>
            <div class="form-group-btn">
              <button type="submit" class="btn btn-save">
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                  style="color: white; margin-bottom: 5px"
                ></span>
                Save
              </button>
              <button type="button" class="btn btn-cancel" (click)="confirmMessge(false)">Cancel</button>
            </div>
          </div>
        </form>
      </li>
      <li class="tab-pane fade" id="privacy">
        <div class="tab-pane-heading">
          <h2 class="title">Notification Settings</h2>
        </div>
        <div class="user-setting-form-wrapper">
          <div class="notification-slide-checkbox">
            <p>Allow Notification</p>
            <label class="switch d-flex align-items-center">
              <input
                type="checkbox"
                name="allowNotifications"
                (change)="changeSettingNotifications($event)"
                [checked]="isNotifications"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <!-- <div class="notification-slide-checkbox">
            <p>Direct Message Notifications</p>
            <label class="switch d-flex align-items-center">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div> -->
          <!-- <div class="notification-slide-checkbox">
            <p>Group Message Notifications</p>
            <label class="switch d-flex align-items-center">
              <input type="checkbox" />
              <span class="slider round"></span>
            </label>
          </div> -->
        </div>
        <div class="tab-pane-heading">
          <h2 class="title">Privacy Settings</h2>
        </div>
        <div class="user-setting-form-wrapper">
          <div class="notification-slide-checkbox">
            <p>Make account public</p>
            <label class="switch d-flex align-items-center">
              <input type="checkbox" (change)="changeSettingPrivacy($event)" [checked]="privacySetting" />
              <span class="slider round"></span>
            </label>
          </div>
          <p>Anyone on kiinder can send you invite</p>
        </div>
      </li>
      <!-- <li class="tab-pane fade" id="audioAndVideo">
        <div class="tab-pane-heading">
          <h2 class="title">Audio and Video</h2>
        </div>
        <div class="user-setting-form-wrapper">
          <div class="form-group">
            <label>Camera</label>
            <input type="text" class="form-control" placeholder="FaceTime HD Camera" />
          </div>
          <div class="form-group">
            <label>Microphone</label>
            <input type="text" class="form-control" placeholder="Default - MacBook Pro Microphone (Built-in)" />
          </div>
          <div class="form-group">
            <label>Speakers</label>
            <input type="text" class="form-control" placeholder="Default - MacBook Pro Speakers (Built-in)" />
          </div>
          <div class="form-group joining-kinder-checkboxes">
            <label>When joining a Kiinder call</label>
            <div class="custom-checkbox">
              <input type="checkbox" id="michrophone" />
              <label for="michrophone">Mute Microphone</label>
            </div>
            <div class="custom-checkbox">
              <input type="checkbox" id="enablevideo" checked />
              <label for="enablevideo">Enable Video</label>
            </div>
          </div>
        </div>
      </li> -->
      <li class="tab-pane fade" id="changePassword">
        <div class="tab-pane-heading">
          <h2 class="title">Change Password</h2>
        </div>
        <form [formGroup]="resetPassword" (ngSubmit)="changePassword()">
          <div class="user-setting-form-wrapper">
            <div class="form-group">
              <label>Old Password</label>
              <input
                type="password"
                formControlName="oldPassword"
                class="form-control"
                placeholder="Old Password"
                [ngClass]="{ 'is-invalid': submitted && verifyForm.oldPassword.errors }"
              />
              <div *ngIf="submitted && verifyForm.oldPassword.errors" class="text-danger errorMsg">
                <div *ngIf="verifyForm.oldPassword.errors.required">Old Password is required.</div>
              </div>
            </div>
            <div class="form-group">
              <label>New Password</label>
              <input
                type="password"
                formControlName="newPassword"
                class="form-control"
                placeholder="New Password"
                [ngClass]="{ 'is-invalid': submitted && verifyForm.newPassword.errors }"
              />
              <div *ngIf="submitted && verifyForm.newPassword.errors" class="text-danger errorMsg">
                <div *ngIf="verifyForm.newPassword.errors.required">New Password is required.</div>
              </div>
              <span
                class="text-danger errorMsg"
                *ngIf="verifyForm.newPassword.touched && verifyForm.newPassword.errors?.invalidPassword"
              >
                Password should have minimum 8 characters, at least 1 uppercase letter and 1 special character.
              </span>
            </div>
            <div class="form-group">
              <label>Confirm New Password</label>
              <input
                type="password"
                class="form-control"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
                [ngClass]="{ 'is-invalid': submitted && verifyForm.confirmPassword.errors }"
              />
              <span
                class="text-danger errorMsg"
                *ngIf="verifyForm.confirmPassword.touched && verifyForm.confirmPassword.errors?.passwordMismatch"
              >
                Passwords does not match
              </span>
            </div>
            <div *ngIf="submitted && verifyForm.confirmPassword.errors" class="text-danger errorMsg">
              <div *ngIf="verifyForm.confirmPassword.errors.required">Confirm Password is required.</div>
            </div>
            <div class="form-group-btn">
              <button type="submit" class="btn btn-save">
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm mr-1"
                  style="color: white; margin-bottom: 5px"
                ></span>
                Save
              </button>
              <button type="button" class="btn btn-cancel" (click)="confirmMessge(false)">Cancel</button>
            </div>
          </div>
        </form>
      </li>
    </ul>
  </div>
</div>
