<div class="mainRightCover">
  <div class="dashmidCover">
    <div class="dashmidSubCover">
      <h2 class="welcomDashTitle">Welcome {{ profile.firstName | titlecase }}!</h2>

      <button type="button" class="skyblueBtn" (click)="newMeeting()">
        <img src="../../assets/video-white.png" class="videoImg" /> New Meeting
      </button>
      <div class="orText">Or</div>
      <button type="button" class="newMeetingBtn" *ngIf="isLink" (click)="joinMeeting()">
        Meeting ID or Personal Link
      </button>
      <form
        class="form-padding"
        *ngIf="isMeetingId"
        #meetingForm="ngForm"
        (ngSubmit)="joinMeetingById(meetingForm.value.id, template, templateInvalid)"
      >
        <div class="form-group email-input">
          <div class="input-group">
            <input
              type="text"
              name="id"
              placeholder="Meeting ID or Personal Link"
              class="form-control newMeetingBtn"
              ngModel
              required
            />
            <button class="joinBtn" type="submit" [disabled]="!meetingForm.valid">Join</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="dashRightCover">
    <div class="dashRightSubCover">
      <div class="dashRightTitle">Upcoming Meeting</div>
      <div class="text-center p-1">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1 text-center"></span>
      </div>
      <div class="dashRightUlCover">
        <div *ngIf="today.length == 0 && !loading && tomorrow.length == 0" class="notFound">No Meeting Found</div>

        <ul>
          <li *ngIf="today.length > 0 && !loading">
            <div class="meetingDetailCover">
              <h4 class="dayText">Today</h4>
              <div class="meetingLRCover" *ngFor="let meetToday of today; index as i">
                <div class="meetingLeft">
                  <div class="pinkTime">{{ meetToday.startTime | date: 'HH:mm' }}</div>
                </div>
                <div class="meetingRight">
                  <div
                    *ngIf="meetToday.userId === profile.id"
                    class="meetingTitle"
                    style="cursor: pointer"
                    (click)="editMeeting(meetToday.id)"
                    title="Edit Meeting"
                  >
                    {{ meetToday.title | titlecase }}
                  </div>
                  <div *ngIf="meetToday.userId !== profile.id" class="meetingTitle">
                    {{ meetToday.title | titlecase }}
                  </div>
                  <div class="meetingTime">
                    {{ meetToday.startTime | date: 'HH:mm' }}-{{
                      totalTime(meetToday.startTime, meetToday.durationTime)
                    }}
                  </div>
                  <div class="hostTitle" *ngIf="meetToday.userId === profile.id">Host: You</div>
                  <div class="upcoming-host hostTitle" *ngIf="meetToday.userId !== profile.id">
                    Host: {{ meetToday.User.fullName | titlecase }}
                  </div>
                  <button
                    *ngIf="meetToday.userId === profile.id"
                    (click)="joinMeetingById(meetToday.meetingId, template, templateInvalid)"
                    type="button"
                    class="btn skyblueBtn"
                  >
                    Start Meeting
                  </button>
                  <button
                    class="btn skyblueBtn"
                    *ngIf="meetToday.userId !== profile.id"
                    (click)="joinMeetingById(meetToday.meetingId, template, templateInvalid)"
                  >
                    Join Meeting
                  </button>
                </div>
              </div>
            </div>
          </li>
          <li *ngIf="tomorrow.length > 0 && !loading">
            <div class="meetingDetailCover">
              <h4 class="dayText">Tomorrow</h4>
              <div class="meetingLRCover" *ngFor="let meetTomorrow of tomorrow; index as i">
                <div class="meetingLeft">
                  <div class="purpleTime">{{ meetTomorrow.startTime | date: 'HH:mm' }}</div>
                </div>
                <div class="meetingRight">
                  <div
                    *ngIf="meetTomorrow.userId === profile.id"
                    class="meetingTitle"
                    style="cursor: pointer"
                    (click)="editMeeting(meetTomorrow.id)"
                    title="Edit Meeting"
                  >
                    {{ meetTomorrow.title | titlecase }}
                  </div>
                  <div *ngIf="meetTomorrow.userId !== profile.id" class="meetingTitle">
                    {{ meetTomorrow.title | titlecase }}
                  </div>
                  <div class="meetingTime">
                    {{ meetTomorrow.startTime | date: 'HH:mm' }}-{{
                      totalTime(meetTomorrow.startTime, meetTomorrow.durationTime)
                    }}
                  </div>

                  <div class="hostTitle" *ngIf="meetTomorrow.userId === profile.id">Host: You</div>
                  <div class="upcoming-host hostTitle" *ngIf="meetTomorrow.userId !== profile.id">
                    Host: {{ meetTomorrow.User.fullName | titlecase }}
                  </div>
                  <button
                    class="btn skyblueBtn"
                    *ngIf="meetTomorrow.userId === profile.id"
                    (click)="joinMeetingById(meetTomorrow.meetingId, template, templateInvalid)"
                  >
                    Start Meeting
                  </button>
                  <button
                    *ngIf="meetTomorrow.userId !== profile.id"
                    (click)="joinMeetingById(meetTomorrow.meetingId, template, templateInvalid)"
                    type="button"
                    class="btn skyblueBtn"
                  >
                    Join Meeting
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-body home-modal-bdy">
    <div class="meting-modal text-center">
      <img src="../../assets/loading.gif" />
      <h4>Connecting...</h4>
      <p>Connecting to the meeting</p>
    </div>

    <div class="home-modal-btns text-center">
      <button class="btn skyblueBtn" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>
<ng-template #templateInvalid>
  <div class="modal-body home-modal-bdy">
    <div class="meting-modal text-center">
      <img src="../../assets/alert-triangle.png" />
      <h4>Invalid Meeting ID</h4>
      <p>Enter a valid meeting ID</p>
    </div>

    <div class="home-modal-btns text-center">
      <button class="btn skyblueBtn" aria-label="Close" (click)="modalRefnew.hide()">Try Again</button>
    </div>
  </div>
</ng-template>
