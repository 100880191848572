<div class="add-contact-popup-wrapper">
  <div class="add-contact-popup-head d-flex justify-content-between">
    <h3>Leave {{ name | titlecase }}’s Kiinder space?</h3>
  </div>
  <div class="leavekinderspace-message">
    <p>Are you sure you want to leave this Kiinder space?</p>
  </div>
  <div class="continue-btn">
    <button type="button" class="cancel-btn" (click)="confirmMessge(false)">Cancel</button>
    <button type="button" class="btn" (click)="confirmMessge(true)">Leave</button>
  </div>
</div>
