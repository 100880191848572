import { Component, HostListener, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import countries from '../_files/allCountries.json';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CropperComponent } from '../image-cropper/cropper.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-setup-profile',
  templateUrl: './setup-profile.component.html',
  styleUrls: ['./setup-profile.component.scss'],
})
export class SetupProfileComponent implements OnInit {
  @ViewChild('personal', { static: false }) navPersonal: ElementRef;
  @ViewChild('preferences', { static: false }) navPreferences: ElementRef;
  @ViewChild('address', { static: false }) navAddresses: ElementRef;
  croperModalRef: BsModalRef;
  subject: Subject<any>;
  countriesList: { country: string; state: [] }[] = countries;
  countries: any = [];
  isBillingAddress = false;
  states: any = [];
  loading = false;
  picLoading = false;
  profilePicUrl: any;

  onBoarding = {
    id: '',
    fullName: '',
    firstName: '',
    lastName: '',
    gender: 'Male',
    firstLanguage: '',
    otherLanguage: '',
    privacySetting: 'public',
    contactPreferences: '',
    profilePicUrl: '',
    role: 'user',
    presonalAddress: {
      address: '',
      country: '',
      state: '',
      postal_code: '',
      type: 'personal',
    },
    billingAddress: {
      address: '',
      country: '',
      state: '',
      postal_code: '',
      type: 'billing',
    },
  };

  submitted: boolean = false;
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private _auth: AuthService,
    private modalService: BsModalService
  ) {
    var profile = JSON.parse(localStorage.getItem('profile'));
    this.onBoarding.id = profile.id;
    this.onBoarding.fullName = `${profile.firstName} ${profile.lastName}`;
    this.onBoarding.firstName = profile.firstName;
    this.onBoarding.lastName = profile.lastName;
  }
  ngOnInit(): void {}
  @HostListener('window:scroll', ['$event']) myScroll() {
    if (window.pageYOffset >= 0 && window.pageYOffset < 690) {
      this.renderer.addClass(this.navPersonal.nativeElement, 'active');
      this.renderer.removeClass(this.navPreferences.nativeElement, 'active');
      this.renderer.removeClass(this.navAddresses.nativeElement, 'active');
    } else if (window.pageYOffset >= 690 && window.pageYOffset < 1100) {
      this.renderer.removeClass(this.navPersonal.nativeElement, 'active');
      this.renderer.addClass(this.navPreferences.nativeElement, 'active');
      this.renderer.removeClass(this.navAddresses.nativeElement, 'active');
    } else if (window.pageYOffset >= 1100) {
      this.renderer.removeClass(this.navPersonal.nativeElement, 'active');
      this.renderer.removeClass(this.navPreferences.nativeElement, 'active');
      this.renderer.addClass(this.navAddresses.nativeElement, 'active');
    }
  }
  getAllSatate(event: any) {
    this.countriesList.map((country: any) => {
      if (country.country === event.value) {
        this.states = country.states;
      }
    });
  }

  submitForm(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.loading = true;
    this.authService
      .onBoardingUser(this.onBoarding)
      .then((data) => {
        if (data == undefined) {
          this.loading = false;
          return;
        } else {
          this.loading = false;
          this.toastr.success('User Onboarding complete.');
        }
      })
      .catch((err) => {});
  }
  sameAsAbove(form: any) {
    if (form.checked) {
      this.onBoarding.billingAddress = {
        address: this.onBoarding.presonalAddress.address,
        country: this.onBoarding.presonalAddress.country,
        state: this.onBoarding.presonalAddress.state,
        postal_code: this.onBoarding.presonalAddress.postal_code,
        type: 'billing',
      };
      this.isBillingAddress = true;
    } else {
      this.onBoarding.billingAddress = {
        address: '',
        country: '',
        state: '',
        postal_code: '',
        type: 'billing',
      };
      this.isBillingAddress = false;
    }
  }

  async handleFileInput(file: any) {
    this.picLoading = true;
    let operations: any = {
      query: `
        mutation($file: Upload!) {
          uploadFile(file: $file) {
            Key
            Location
          }
        }
      `,
      variables: {
        file: null,
      },
    };
    let _map = {
      file: ['variables.file'],
    };
    var fd = new FormData();
    fd.append('operations', JSON.stringify(operations));
    fd.append('map', JSON.stringify(_map));
    fd.append('file', file);
    this.authService.uploadImage(fd).then((res: any) => {
      if (res.data.errors) {
        this.picLoading = false;

        res.data.errors &&
          res.data.errors.map((err: any) => {
            this.toastr.error(err.message, 'Error');
          });
        return;
      }
      this.profilePicUrl = res.data.data.uploadFile.Location;
      this.onBoarding.profilePicUrl = this.profilePicUrl;
      this.picLoading = false;
    });
  }

  showCropper() {
    const subject = new Subject<any>();
    this.croperModalRef = this.modalService.show(CropperComponent, {
      class: 'modal-md-invitePeople modal-dialog-centered',
    });
    this.croperModalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data) {
        let file = this.dataURLtoFile(data.image, data.original.name);
        this.handleFileInput(file);
        this.croperModalRef.hide();
      } else {
        this.croperModalRef.hide();
      }
    });
  }
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
