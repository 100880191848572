import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { MeetingService } from '@app/services/meeting.service';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { VideochatService } from '../../services/videochat.service';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@app/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-new-meeting',
  templateUrl: './new-meeting.component.html',
  styleUrls: ['./new-meeting.component.scss'],
  host: { class: 'appnewMeeting' },
})
export class NewMeetingComponent implements OnInit {
  private subject = new Subject<any>();
  loading = false;
  isFutureTime = false;
  name: string;
  userSelectsString = '';
  subscription: Subscription;
  userSelects: any = [];
  meetingUrl = '';
  isTime = true;
  newMeeting = {
    meetingManualId: '',
    userId: '',
    title: '',
    startTime: '',
    durationTime: {
      hour: 0,
      minutes: 0,
    },
    attendess: '',
  };
  start = {
    date: '',
    time: '',
  };
  saveLoding = false;
  hours = [
    { hour: 1 },
    { hour: 2 },
    { hour: 3 },
    { hour: 4 },
    { hour: 5 },
    { hour: 6 },
    { hour: 7 },
    { hour: 8 },
    { hour: 9 },
    { hour: 10 },
    { hour: 11 },
    { hour: 12 },
  ];
  attendess: any = [];
  minutes: any = [];
  meridian = true;
  submitted = false;
  submittedForm = false;
  profile: any;
  chooseDate: any;
  constructor(
    private config: NgbDatepickerConfig,
    private meetingService: MeetingService,
    private location: Location,
    private toastr: ToastrService,
    private videoChatService: VideochatService,
    private _auth: AuthService
  ) {
    const current = new Date();
    config.minDate = { year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate() };
    config.outsideDays = 'hidden';
    this.getMinutes();
    this.profile = this._auth.getProfileFromToken();
    this.newMeeting.userId = this.profile.id;
    this.loading = true;
    this.videoChatService
      .createRoom()
      .then((meeting) => {
        this.newMeeting.meetingManualId = meeting.meetingId;
        this.meetingUrl = `https://meeting.kiinder.com/${meeting.meetingId}`;
        this.loading = false;
      })
      .catch((error) => {
        this.toastr.error(error.message, 'Error');
        this.loading = false;
      });
  }
  ngOnInit(): void {}
  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('Link copied successfully.', 'Success');
  }
  cancel() {
    this.location.back();
  }
  suggest() {
    this.show = true;
  }
  submitForm(event: any) {
    this.submittedForm = true;
    if (!event.valid) {
      return;
    }
    let year = event.value.startDate.year;
    let month = event.value.startDate.month - 1;
    let day = event.value.startDate.day <= 9 ? '0' + event.value.startDate.day : event.value.startDate.day;
    let hour = event.value.startTime.hour ? event.value.startTime.hour : 0;
    let minute = event.value.startTime.minute ? event.value.startTime.minute : 0;
    this.newMeeting.startTime = new Date(year, month, day, hour, minute).toISOString();
    this.newMeeting.durationTime.hour = Number(event.value.durationHour) ? Number(event.value.durationHour) : 0;
    this.newMeeting.durationTime.minutes = Number(event.value.durationMinute) ? Number(event.value.durationMinute) : 0;
    this.newMeeting.attendess = this.userSelects;
    if (this.userSelects.length > 0) {
      this.saveLoding = true;
      this.meetingService
        .newMeeting(this.newMeeting)
        .then((response) => {
          if (response.data.errors) {
            this.toastr.error(response.data.error[0].message);
            this.saveLoding = false;
            return;
          }
          this.saveLoding = false;
          this.toastr.success(`New meeting is generated`, 'Success');
          this.location.back();
        })
        .catch((error) => {
          this.saveLoding = false;
          this.toastr.error(error.message);
        });
    } else {
      this.saveLoding = false;
      this.toastr.error(`Please add atleast one attendee.`, 'Error');
    }
  }

  show: boolean = false;
  getMinutes() {
    for (var i = 1; i <= 12; i++) {
      var numString = (i * 5).toString();
      var paddedNum = numString.padStart(2, '0');
      this.minutes.push({
        minute: paddedNum,
        value: i,
      });
    }
  }
  deleteSelects(s: any) {
    this.userSelects = this.userSelects.filter((item: any, index: Number) => index !== s);
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  async addAttendees(form: any) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    if (form.value) {
      if (this.userSelectsString === this.profile.email) {
        this.toastr.error('You cannot add yourself to it', 'Error');
        return;
      }
      let unique =
        this.userSelects.length > 0 && this.userSelects.find((item: any) => item.email === this.userSelectsString);

      if (unique) {
        this.toastr.error('Already added', 'Error');
        this.submitted = false;
        return;
      } else {
        let user = await this._auth.getUserByEmailId(this.userSelectsString);
        if (user) {
          this.userSelects.push({
            email: user.email,
            name: user.fullName,
            profilePicUrl: user.profilePicUrl,
          });
          this.userSelectsString = '';
          this.submitted = false;
        } else {
          this.userSelects.push({
            email: this.userSelectsString,
            name: '',
            profilePicUrl: '',
          });
          this.userSelectsString = '';
          this.submitted = false;
        }
      }
    }
  }
  checkDate(event: any) {
    let date = new Date(event?.year, event?.month - 1, event?.day);
    let currentDate = new Date();
    this.chooseDate = moment(date).format('DD-MM-YYYY');
    if (date > currentDate) {
      this.isFutureTime = false;
    }
    this.isTime = false;
  }
  checkTime(event: any) {
    if (event !== null) {
      let d = new Date();
      d.setHours(event.hour);
      d.setMinutes(event.minute);
      let currentDate = moment().format('DD-MM-YYYY');
      let currentTime = new Date();
      if (this.chooseDate == currentDate) {
        if (d < currentTime) {
          this.isFutureTime = true;
        } else {
          this.isFutureTime = false;
        }
      }
    }
  }
}
