import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.scss'],
})
export class PermissionComponent implements OnInit {
  subject: Subject<boolean>;
  constructor() {}

  ngOnInit(): void {}
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
}
