import { Injectable } from '@angular/core';
import { axiosPost, axiosUploadFile } from './api';
import {
  createOrganization,
  inviteUserToOrganization,
  userRejectInvitaion,
  userAcceptInvitation,
  removeUserFromOrganisation,
  leaveOrganization,
  changeOrgMemberRole,
  updateOrganization,
} from '../mutations/organization';
import {
  getOrganizationByUserId,
  getOrganizationInvitationByEmailId,
  getOrganizationDetailByUserId,
  getOrganizationDetailById,
  getOrganizationDetailByOrgIdAndUserId,
  getOrganizationUserDetailByEmailId,
} from '../queries/organization';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private toastr: ToastrService) {}

  createOrganization(obj: any) {
    try {
      return axiosPost(createOrganization(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.createOrganization) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationByUserId(id: any) {
    try {
      return axiosPost(getOrganizationByUserId(id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationByUserId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationDetailByUserId(id: any) {
    try {
      return axiosPost(getOrganizationDetailByUserId(id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationDetailByUserId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationInvitationByEmailId(email: any) {
    try {
      return axiosPost(getOrganizationInvitationByEmailId(email)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationInvitationByEmailId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  inviteUserToOrganization(obj: any) {
    try {
      return axiosPost(inviteUserToOrganization(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.inviteUserToOrganization !== null) {
          this.toastr.success('Invited');
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  userRejectInvitaion(obj: any) {
    try {
      return axiosPost(userRejectInvitaion(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.userRejectInvitaion) {
          this.toastr.error('Invitation Rejected.');
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  userAcceptInvitation(obj: any) {
    try {
      return axiosPost(userAcceptInvitation(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.userAcceptInvitation) {
          this.toastr.success('Add to organization successfully.');
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  removeUserFromOrganisation(obj: any) {
    try {
      return axiosPost(removeUserFromOrganisation(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.removeUserFromOrganisation) {
          this.toastr.success(response.data.data.removeUserFromOrganisation.message);
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  leaveOrganization(organizationId: any, id: any) {
    try {
      return axiosPost(leaveOrganization(organizationId, id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.leaveOrganization) {
          this.toastr.success(response.data.data.leaveOrganization.message);
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationDetailById(id: any) {
    try {
      return axiosPost(getOrganizationDetailById(id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationDetailById) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationDetailByOrgIdAndUserId(userId: any, orgId: any) {
    try {
      return axiosPost(getOrganizationDetailByOrgIdAndUserId(userId, orgId)).then((response) => {
        console.log('getOrganizationDetailByOrgIdAndUserId', response);
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationDetailByOrgIdAndUserId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  changeOrgMemberRole(obj: any) {
    try {
      return axiosPost(changeOrgMemberRole(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.changeOrgMemberRole) {
          this.toastr.success(response.data.data.changeOrgMemberRole.message);
          return response.data.data.changeOrgMemberRole;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  updateOrganization(obj: any) {
    try {
      return axiosPost(updateOrganization(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.updateOrganization) {
          this.toastr.success(response.data.data.updateOrganization.message);
          return response.data.data.updateOrganization;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getOrganizationUserDetailByEmailId(email: any, orgId: any) {
    try {
      return axiosPost(getOrganizationUserDetailByEmailId(email, orgId)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationUserDetailByEmailId) {
          return response.data.data.getOrganizationUserDetailByEmailId;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
}
