<div class="user-setting-modal-wrapper">
  <div class="user-setting-modal-right">
    <h2 class="title">Edit Organization</h2>
    <button type="button" class="close" (click)="confirmMessge(false)">
      <i class="bi bi-x-circle"></i>
    </button>
    <div class="modal-body">
      <form #editForm="ngForm" (ngSubmit)="updateOrganization(editForm)">
        <div class="tab-pane-heading">
          <div class="setup-profile-avatar">
            <img class="avatar" src="{{ logo }}" alt="" />
            <div class="PicLoader text-center" *ngIf="picLoading">
              <span class="spinner-border spinner-border-sm mr-1 text-center"></span>
            </div>
            <label class="edit-profile-pic anc-pointer" (click)="showCropper()"
              ><img src="../../assets/profile-edit.svg" alt=""
            /></label>
          </div>
        </div>
        <div class="user-setting-form-wrapper">
          <div class="form-group">
            <label for="organizationName">Oragnization Name:</label>
            <input
              type="text"
              class="form-control"
              name="organizationName"
              [(ngModel)]="obj.organizationName"
              #organizationName="ngModel"
              ngModel
            />
            <div
              *ngIf="
                submitted &&
                organizationName?.invalid &&
                (organizationName?.dirty || organizationName?.touched || organizationName?.untouched)
              "
              class="error-container"
            >
              Organization Name is required.
            </div>
          </div>
          <div class="form-group-btn">
            <button type="submit" class="btn btn-save">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
                style="color: white; margin-bottom: 5px"
              ></span>
              Save
            </button>
            <button type="button" class="btn btn-cancel" (click)="confirmMessge(false)">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
