import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../auth/login.component';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.scss'],
})
export class MeetingComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(private modalService: BsModalService) {}
  ngOnInit(): void {}
  logIn(id: any) {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(LoginComponent, {
      class: 'modal-lg-login modal-dialog-centered signupModal',
      initialState: { id: id },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.modalRef.hide();
      }
    });
  }
}
