import { Component, OnInit } from '@angular/core';

import { AuthService } from '@app/services/auth.service';
import { ContactsService } from '@app/services/contacts.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AddContactComponent } from '../add-contact/add-contact.component';

@Component({
  selector: 'app-my-contact-meta',
  templateUrl: './my-contact-meta.component.html',
  styleUrls: ['./my-contact-meta.component.scss'],
})
export class MyContactMetaComponent implements OnInit {
  modalRef: BsModalRef;
  profile: any;
  contacts: any = [];
  count: any = [];
  isLoading = false;
  constructor(
    private modalService: BsModalService,
    private _contactsService: ContactsService,
    private _auth: AuthService
  ) {
    this.profile = this._auth.getProfileFromToken();
  }

  ngOnInit(): void {
    this.getContacts('');
  }
  getContacts(searchText: any) {
    this.isLoading = true;
    this._contactsService.getContactByUserIdMeta(this.profile.id, searchText).then((res) => {
      if (res === undefined) {
        this.isLoading = false;
        return;
      }

      this.count = res.data.data.getContactByUserIdMeta.sort(function (a: any, b: any) {
        if (a.fullName < b.fullName) {
          return -1;
        }
        if (a.fullName > b.fullName) {
          return 1;
        }
        return 0;
      });
      let data = this.count.reduce((r: any, e: any) => {
        let group = e.fullName[0];
        if (!r[group]) r[group] = { group, children: [e] };
        else r[group].children.push(e);
        return r;
      }, {});
      this.contacts = Object.values(data);
      this.isLoading = false;
      console.log('contacts', this.contacts);
    });
  }
  addContact() {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(AddContactComponent, {
      class: 'modal-md-addcontact modal-dialog-centered',
      initialState: { belongTo: 'meta' },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.contacts = [];
        this.getContacts('');
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  editContact(id: any) {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(AddContactComponent, {
      class: 'modal-md-editcontact modal-dialog-centered',
      initialState: { id: id },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      console.log('data', data);
      if (data === true) {
        this.contacts = [];
        this.getContacts('');
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  deleteContact(id: any) {
    this._contactsService.deleteContact(id).then((res) => {
      if (res === undefined) {
        return;
      }
      this.getContacts('');
    });
  }
  searchText(event: any) {
    this.getContacts(event.value);
  }
}
