import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kinder-meeting',
  templateUrl: './kinder-meeting.component.html',
  styleUrls: ['./kinder-meeting.component.scss'],
})
export class KinderMeetingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
