import { identifierModuleUrl } from '@angular/compiler';

export function getOrganizationByUserId(id: any) {
  return `
  query{
    getOrganizationByUserId(id:${id}){
      organizationName
      logo
      userId
    }
  }`;
}
export function getOrganizationInvitationByEmailId(email: any) {
  return `query{
    getOrganizationInvitationByEmailId(email:"${email}"){
      id
      organizationId
      email
      Organization{
        organizationName
        logo
        userId
      }
    }
  }`;
}
export function getOrganizationDetailByUserId(id: any) {
  return `
  query{
    getOrganizationDetailByUserId(id:${id}){
      id
      userId
      Organization{
        id
        organizationName
        logo
        manualId
      }
      OrganizationRole{
        roleName
      }
    }
  }`;
}
export function getOrganizationDetailById(id: any) {
  return `query{
    getOrganizationDetailById(id:${id}){
      id
      organizationName
      logo
      manualId
      privacy
      userId
    }
  }`;
}
export function getOrganizationDetailByOrgIdAndUserId(userId: any, orgId: any) {
  return `query{
    getOrganizationDetailByOrgIdAndUserId(
      userId:${userId}
      orgId:${orgId}
    ){
      id
      userId
      isNotifications
      Organization{
        id
        organizationName
        logo
        manualId
      }
      OrganizationRole{
        roleName
      }
      Permission{
        id
        name
        groupType
      }
    }
  }`;
}
export function getOrganizationUserDetailByEmailId(email: any, orgId: any) {
  return `query{
    getOrganizationUserDetailByEmailId(orgId:${orgId}
    email:"${email}"){
      fullName
      profilePicUrl
      email
    }
  }`;
}
