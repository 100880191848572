import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { localStorageKeys } from '../helpers/index';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { axiosPost } from './api';
import { getVideoToken, getTwilioVideoRooms, createRoom } from '../queries/video';
import { BroadcastService } from '../services/broadcast.service';

export interface NamedRoom {
  id: string;
  name: string;
  maxParticipants?: number;
  participantCount: number;
}

export type Rooms = NamedRoom[];
@Injectable({
  providedIn: 'root',
})
export class VideochatService {
  $roomsUpdated: Observable<boolean>;
  private roomBroadcast = new ReplaySubject<boolean>();
  public videoConnectedEmitter: EventEmitter<any> = new EventEmitter<boolean>();
  public videoDisconnectedEmitter: EventEmitter<any> = new EventEmitter<boolean>();

  constructor(
    private readonly http: HttpClient,
    private toastr: ToastrService,
    private broadcastService: BroadcastService
  ) {
    this.$roomsUpdated = this.roomBroadcast.asObservable();
  }

  // private async getAuthToken() {
  //   const auth = await this.http.get<AuthToken>(`/video/token`).toPromise();

  //   return auth.token;
  // }
  getVideoToken(username: any) {
    try {
      return axiosPost(getVideoToken(username)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getTwilioVideoToken && response.data.data.getTwilioVideoToken.token) {
          return response.data.data.getTwilioVideoToken.token;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  setToken(token: any) {
    localStorage.setItem(localStorageKeys.TwilioVideoToken, token);
  }
  // getAllRooms() {
  //   // return this.http.get<Rooms>('api/video/rooms').toPromise();
  //   try {
  //     return axiosPost(getTwilioVideoRooms()).then((response) => {
  //       console.log('resposdjfjl', response.data.data.getTwilioVideoRooms.rooms);
  //       return response.data.data.getTwilioVideoRooms.rooms;
  //       // if (response.data.errors && response.data.errors.length > 0) {
  //       //   this.toastr.error(response.data.errors[0].message);
  //       //   return;
  //       // }
  //       // if (response.data.data.getTwilioVideoToken && response.data.data.getTwilioVideoToken.token) {
  //       //   this.setToken(response.data.data.getTwilioVideoToken.token);
  //       //   this.toastr.success('User registered successfully');
  //       //   return;
  //       // }
  //     });
  //   } catch (error) {
  //     console.log(error, 'sssssssssssssss');
  //     this.toastr.error(error.message);
  //   }
  // }
  createRoom() {
    try {
      var first = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 4; i++) {
        first += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      var second = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 5; i++) {
        second += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      var third = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < 4; i++) {
        third += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      var meeting = `${first}-${second}-${third}`;

      return axiosPost(createRoom(meeting)).then((response) => {
        return response.data.data.createVideoRoom;
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  async joinOrCreateRoom(name: string, tracks: LocalTrack[], token: string) {
    let room: Room = null;
    try {
      room = await connect(token, {
        name,
        tracks,
        dominantSpeaker: true,
        networkQuality: true,
      } as ConnectOptions);
    } catch (error) {
      console.error(`Unable to connect to Room: ${error.message}`);
      this.toastr.error(`Unable to connect to Room: ${error.message}`, 'Error');
      this.broadcastService.sendMessage({ type: 'isConnected', message: false });
    } finally {
      if (room) {
        this.toastr.success('Connect to Meeting Successfully.');
        this.broadcastService.sendMessage({ type: 'isConnected', message: false });
        this.videoConnectedEmitter.emit(true);
        this.roomBroadcast.next(true);
      }
    }
    return room;
  }

  nudge() {
    this.roomBroadcast.next(true);
  }
}
