<!-- <div class="landing-page-title">
  <h2>Contacts ({{contacts.length}})</h2>
</div>

<div class="landing-page-container">
  <div class="find-contact-group">
    <input type="text" placeholder="Find a contact" class="form-control" />

    <button *ngIf="contacts.length >0" class="btn" (click)="addContact()"> <i class="bi bi-plus"></i> Add Contact
    </button>


  </div>
  <div class="contact-table">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let contact of contacts index as i">
            <td>{{contact.fullName | titlecase}}</td>
            <td>{{contact.email}}</td>
            <td>{{contact.phone}}</td>
            <td>
              <div ngbDropdown class="d-inline-block">
                <button class="dropdown-menu-right border-0 " id="dropdownBasic1" ngbDropdownToggle>...</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="contact-drop">
                  <button ngbDropdownItem>Edit Contact <i class="far fa-edit"></i> </button>
                  <div class="dropdown-divider"></div>
                  <button ngbDropdownItem>Delete Contact <i class="far fa-trash-alt"></i></button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="contact-list-empty text-center" *ngIf="contacts.length ===0">
    <p>Contacts list is empty</p>
    <button class="btn" (click)="addContact()"> <i class="bi bi-plus"></i> Add Contact </button>
  </div>
</div> -->
<div class="landing-page-title">
  <h2>Contacts ({{ count.length }})</h2>
</div>

<div class="landing-page-container">
  <div class="find-contact-group d-flex align-self-start">
    <form #searchForm="ngForm">
      <input
        type="text"
        placeholder="Find a contact"
        name="search"
        class="form-control"
        (ngModelChange)="searchText(search)"
        #search="ngModel"
        ngModel
      />
    </form>
    <a *ngIf="count.length > 0" (click)="addContact()" style="cursor: pointer; margin-left: 8px">
      <i class="bi bi-plus"></i> Add Contact
    </a>
  </div>
  <div class="contact-table">
    <div class="table-responsive">
      <table class="table t-res">
        <thead>
          <tr>
            <th class="contact-name">Name</th>
            <th class="contact-role">Role</th>
            <th class="contact-phone">Phone</th>
            <th class="contact-phone"></th>
          </tr>
        </thead>

        <div class="text-center loader" *ngIf="isLoading">
          <span class="spinner-border spinner-border-sm mr-1 text-center"></span>
        </div>
        <tbody *ngIf="!isLoading">
          <ng-container *ngFor="let group of contacts; index as i">
            <tr class="contact-alphabat">
              <td colspan="4">{{ group.group | titlecase }}</td>
            </tr>
            <tr *ngFor="let contact of group.children">
              <td>
                <!-- <div class="contact-member-profile">
                  <figure>
                    <img src="../../assets/avater-profile.png" alt="" />
                  </figure>
                  <p>{{ contact.fullName | titlecase }}</p>
                </div> -->
                <div ngbDropdown class="contacInfo">
                  <a
                    class="dropdown-menu-right border-0 contact-phone-option-btn"
                    id="dropdownBasic1"
                    style="cursor: pointer"
                    ngbDropdownToggle
                  >
                    <div class="contact-member-profile">
                      <figure>
                        <img
                          src="{{ contact.profilePicUrl ? contact.profilePicUrl : '../../assets/avater-profile.png' }}"
                          alt=""
                        />
                      </figure>
                      <p>{{ contact.fullName | titlecase }}</p>
                    </div>
                  </a>

                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="contact-drop dropdown-wrap">
                    <div class="contact-content">
                      <div>
                        <h3>{{ contact.fullName | titlecase }}</h3>
                        <p *ngIf="contact.roleName">{{ contact.roleName }}</p>
                        <p *ngIf="!contact.roleName">N/A</p>
                        <p *ngIf="contact.phone">{{ contact.phone }}</p>
                        <p *ngIf="!contact.phone">N/A</p>
                        <p>{{ contact.email }}</p>
                      </div>
                      <div>
                        <img src="../../../assets/avater-profile.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <!-- <p *ngIf="contact.roleName">{{ contact.roleName }}</p> -->

                <select
                  *ngIf="!contact.phone"
                  name="role"
                  id="role"
                  [(ngModel)]="contact.roleId"
                  class="form-control roleSelect"
                  (change)="changeRole($event, contact.id)"
                >
                  <option value="" *ngFor="let role of roles" [ngValue]="role.id">{{ role.roleName }}</option>
                </select>

                <p *ngIf="contact.phone">{{ contact.roleName }}</p>
              </td>
              <td>
                <div class="contact-phone-row">
                  <h4 *ngIf="contact.phone">{{ contact.phone }}</h4>
                  <h4 *ngIf="!contact.phone">N/A</h4>
                </div>
              </td>
              <td>
                <div class="contact-phone-options">
                  <!-- <a href="#" class="contact-phone-option-btn">
                  <i class="bi bi-telephone"></i>
                </a>
                <a href="#" class="contact-phone-option-btn">
                  <i class="bi bi-chat"></i>
                </a>
                <a href="#" class="contact-phone-option-btn">
                  <i class="bi bi-camera-video"></i>
                </a> -->

                  <div ngbDropdown class="contact-phone-edit-delete">
                    <!-- <a href="#" class="contact-phone-option-btn">
                    <i class="bi bi-three-dots"></i>
                  </a> -->

                    <a
                      class="dropdown-menu-right border-0 contact-phone-option-btn"
                      id="dropdownBasic1"
                      ngbDropdownToggle
                    >
                      <i class="bi bi-three-dots"></i>
                    </a>
                    <div
                      *ngIf="contact.isExist === 'true'"
                      ngbDropdownMenu
                      placement="bottom-left"
                      aria-labelledby="dropdownBasic1"
                      class="contact-drop dropdown-wrap"
                    >
                      <a ngbDropdownItem (click)="removeUserFromOrganisation(contact.id)">
                        <p>Delete Contact</p>
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </div>
                    <div
                      *ngIf="contact.isExist === 'false'"
                      ngbDropdownMenu
                      aria-labelledby="dropdownBasic1"
                      class="contact-drop dropdown-wrap"
                    >
                      <a ngbDropdownItem (click)="editContact(contact.id)">
                        <p>Edit Contact</p>
                        <i class="far fa-edit"></i>
                      </a>
                      <a ngbDropdownItem (click)="deleteContact(contact.id)">
                        <p>Delete Contact</p>
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </div>

                    <!-- <div class="dropdown-wrap">
                    <a href="#">
                      <p>Edit Contact</p>
                      <i class="bi bi-pencil-square"></i>
                    </a>
                    <a href="#">
                      <p>Delete Contact</p>
                      <i class="bi bi-trash"></i>
                    </a>
                  </div> -->
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="contact-list-empty text-center" *ngIf="count.length === 0 && !isLoading">
    <p>Contacts list is empty</p>
    <a (click)="addContact()"> <i class="bi bi-plus"></i> Add Contact </a>
  </div>
</div>
