import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardComponent } from '../app/dashboard/dashboard.component';
import { ShellComponent } from '../app/shell/shell.component';
import { ChatComponent } from '../app/chat/chat.component';
import { SetupProfileComponent } from '../app/setup-profile/setup-profile.component';
import { MeetingLeftComponent } from '../app/meeting-left/meeting-left.component';
import { KinderMeetingComponent } from '../app/kinder-meeting/kinder-meeting.component';
import { FrontInvitationComponent } from './frontInvitaion/front-invitations.component';
import { Authorization } from './helpers/authorization';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LandingComponent } from './landing/landing.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'setup-profile',
    component: SetupProfileComponent,
  },
  // {
  //   path: 'new-meeting',
  //   loadChildren: () => import('./meeting-module/meeting.module').then((m) => m.MeetingModule),
  //   canActivate: [Authorization],
  // },

  // {
  //   path: 'meta',
  //   loadChildren: () => import('./meta-module/meta-module.module').then((m) => m.MetaModuleModule),
  //   canActivate: [Authorization],
  // },

  {
    path: 'invitations',
    component: FrontInvitationComponent,
    canActivate: [Authorization],
  },
  // {
  //   path: 'meet/:id',
  //   component: VideohomeComponent,
  //   canActivate: [Authorization],
  // },
  {
    path: 'meet',
    loadChildren: () => import('./videocalling/video-calling.module').then((m) => m.VideoCallingModule),
    canActivate: [Authorization],
  },
  // {
  //   path: 'landing',
  //   loadChildren: () => import('./landing/landing-module.module').then((m) => m.LandingModuleModule),
  //   canActivate: [Authorization],
  // },
  {
    path: 'left/:id',
    component: MeetingLeftComponent,
    canActivate: [Authorization],
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [Authorization],
    children: [
      {
        path: '',
        loadChildren: () => import('./meta-module/meta-module.module').then((m) => m.MetaModuleModule),
        canActivate: [Authorization],
      },
      {
        path: 'org/:id',
        loadChildren: () =>
          import('./organization-module/organization-module.module').then((m) => m.OrganizationModuleModule),
        canActivate: [Authorization],
      },
      {
        path: 'new-meeting',
        loadChildren: () => import('./meeting-module/meeting.module').then((m) => m.MeetingModule),
        canActivate: [Authorization],
      },
      {
        path: 'orgMeeting/:orgId',
        loadChildren: () => import('./org-meeting-module/org-meeting.module').then((m) => m.OrgMeetingModule),
        canActivate: [Authorization],
      },
    ],
  },

  {
    path: 'shell',
    component: ShellComponent,
    canActivate: [Authorization],
    children: [
      {
        path: 'home',
        component: HomeComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },

      {
        path: 'kinder-meeting',
        component: KinderMeetingComponent,
      },
    ],
  },
  { path: '', redirectTo: 'landing/contacts', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
