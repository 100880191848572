import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { MeetingService } from '../../services/meeting.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
})
export class InviteUserComponent implements OnInit {
  subject: Subject<boolean>;
  meetingId: any;
  isSubmitted = false;
  name = '';
  submitted = false;
  constructor(private _meetingService: MeetingService, private toastr: ToastrService) {}

  ngOnInit(): void {}
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    let user = {
      meetingId: this.meetingId,
      email: form.value.email,
    };
    this._meetingService.addParticipantToMeeting(user).then((r) => {
      console.log('res', r.data.data.addParticipantToMeeting.message);
      if (r.data.errors) {
        this.toastr.error(r.data.error[0].message);
        return;
      }
      if (r.data.data.addParticipantToMeeting.message === 'Exists') {
        this.toastr.error('Participant already exists');
      } else if (r.data.data.addParticipantToMeeting.message === 'Added') {
        this.toastr.success('Participant added');
        this.subject.next(true);
        this.subject.complete();
      }
    });
  }
}
