<!-- <div class="setup-profile-header py-2 px-3">
  <img class="home-gray" src="../../../assets/logo.png" />
</div> -->

<div class="user-profile-listing-wrapper">
  <!-- <div class="users-listing-bar">
    <ul>
      <li class="active online">
        <figure>
          <img src="../../../assets/user-profile.png" alt="" />
        </figure>
      </li>
      <li class="online">
        <figure>
          <img src="../../../assets/user-profile.png" alt="" />
        </figure>
      </li>
    </ul>
    <a href="#" class="add-users-btn">
      <i class="bi bi-plus-circle-fill"></i>
    </a>
  </div> -->
  <div class="new-meeting-main">
    <h4 class="sec-heading">Edit Meeting</h4>
    <div class="newmeeting-form-wrapper">
      <form #meetingForm="ngForm" (ngSubmit)="submitForm(meetingForm)">
        <div class="row">
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="newMeetingParent">
              <form class="form-padding">
                <div class="form-group email-input copy-input-meting">
                  <div *ngIf="loading"><span class="spinner-border spinner-border-sm mr-1 text-center"></span></div>
                  <div *ngIf="!loading" class="input-group">
                    <input
                      value="test"
                      class="newmeetingInput"
                      type="text"
                      name="copy"
                      [(ngModel)]="meetingUrl"
                      #userinput
                      readonly
                    />
                    <img src="../../assets/copy.png" style="cursor: pointer" (click)="copyInputMessage(userinput)" />
                  </div>
                </div>
              </form>
            </div>
            <div class="newMeetingParent">
              <div class="form-padding">
                <div class="form-group email-input">
                  <label for="email">Add Title</label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="title"
                      [(ngModel)]="newMeeting.title"
                      required
                      placeholder="Add Title"
                      #title="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          submittedForm && title?.invalid && (title?.dirty || title?.touched || title?.untouched)
                      }"
                      class="form-control"
                    />
                    <div
                      *ngIf="submittedForm && title?.invalid && (title?.dirty || title?.touched || title?.untouched)"
                      class="error-container"
                    >
                      Title is required.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Start Time</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div class="date-input">
                      <div class="input-group">
                        <input
                          class="form-control"
                          placeholder="yyyy-mm-dd"
                          required
                          name="startDate"
                          [(ngModel)]="defultDate"
                          ngbDatepicker
                          autocomplete="off"
                          #date="ngbDatepicker"
                          (ngModelChange)="checkDate($event)"
                          #requiredDate="ngModel"
                          [ngClass]="{
                            'is-invalid':
                              submittedForm &&
                              requiredDate?.invalid &&
                              (requiredDate?.dirty || requiredDate?.touched || requiredDate?.untouched)
                          }"
                        />
                        <div class="input-group-append claendar-div">
                          <button class="btn btn-outline-secondary calendar" (click)="date.toggle()" type="button">
                            <i class="fas fa-calendar-week"></i>
                          </button>
                        </div>
                        <div
                          *ngIf="
                            submittedForm &&
                            requiredDate?.invalid &&
                            (requiredDate?.dirty || requiredDate?.touched || requiredDate?.untouched)
                          "
                          class="error-container"
                        >
                          Date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 form-group">
                    <div class="time-input">
                      <div class="input-group">
                        <ngb-timepicker
                          [(ngModel)]="defulTime"
                          name="startTime"
                          class="time-picker"
                          [meridian]="meridian"
                          (ngModelChange)="checkTime($event)"
                          (keyup)="checkTime($event)"
                          [disabled]="isTime"
                        >
                        </ngb-timepicker>
                        <span *ngIf="isFutureTime" class="error-time">Please choose the upcoming time</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <label>Duration</label>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group email-input">
                      <div class="input-group">
                        <select
                          class="form-control"
                          name="durationHour"
                          #hour="ngModel"
                          [(ngModel)]="newMeeting.durationTime.hour"
                          [ngClass]="{
                            'is-invalid':
                              submittedForm && hour?.invalid && (hour?.dirty || hour?.touched || hour?.untouched)
                          }"
                        >
                          <option value="0">Hours</option>
                          <option [ngValue]="hour.hour" *ngFor="let hour of hours">
                            {{ hour.hour }}
                          </option>
                        </select>
                        <!-- <div
								*ngIf="submittedForm && hour?.invalid && (hour?.dirty || hour?.touched || hour?.untouched)"
								class="error-container"
							  >
								Hours is required.
							  </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="form-group email-input">
                      <div class="input-group">
                        <select
                          class="form-control"
                          name="durationMinute"
                          #min="ngModel"
                          ngModel
                          [(ngModel)]="newMeeting.durationTime.minutes"
                          [ngClass]="{
                            'is-invalid':
                              submittedForm && min?.invalid && (min?.dirty || min?.touched || min?.untouched)
                          }"
                        >
                          <option value="0">Minutes</option>
                          <option [ngValue]="minute.minute" *ngFor="let minute of minutes">
                            {{ minute.minute }}
                          </option>
                        </select>
                        <!-- <div
								*ngIf="submittedForm && min?.invalid && (min?.dirty || min?.touched || min?.untouched)"
								class="error-container"
							  >
								Minutes is required.
							  </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="form-group email-input">
                  <label for="email">Choose WorkFlow</label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="WorkFlow"
                      [(ngModel)]="newMeeting.WorkFlow"
           
                      placeholder="WorkFlow"
                      #workFlow="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          submittedForm && workFlow?.invalid && (workFlow?.dirty || workFlow?.touched || workFlow?.untouched)
                      }"
                      class="form-control"
                    />
                    <div
                      *ngIf="submittedForm && workFlow?.invalid && (workFlow?.dirty || workFlow?.touched || workFlow?.untouched)"
                      class="error-container"
                    >
                      Title is required.
                    </div>
                  </div>
                </div> -->
                <!-- <div class="form-group email-input">
                  <label for="email">Select Host</label>
                  <div class="input-group">
                    <input
                      type="text"
                      name="Host"
                      [(ngModel)]="newMeeting.host"
                     
                      placeholder="Add Host"
                      #host="ngModel"
                      [ngClass]="{
                        'is-invalid':
                          submittedForm && host?.invalid && (host?.dirty || host?.touched || host?.untouched)
                      }"
                      class="form-control"
                    />
                    <div
                      *ngIf="submittedForm && host?.invalid && (host?.dirty || host?.touched || host?.untouched)"
                      class="error-container"
                    >
                      Host is required.
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-sm-12">
            <div class="form-padding">
              <div class="autocomplete">
                <div class="form-group email-input">
                  <label>Invite Attendees</label>
                  <div class="input-group chips-input-container">
                    <input
                      name="emailId"
                      type="text"
                      placeholder="User"
                      class="form-control"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      [(ngModel)]="userSelectsString"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          suggestion?.invalid &&
                          (suggestion?.dirty || suggestion?.touched || suggestion?.untouched)
                      }"
                      placeholder="Email"
                      #suggestion="ngModel"
                    />
                    <span (click)="addAttendees(suggestion)"><i class="fas fa-plus"></i></span>
                    <div
                      *ngIf="
                        submitted &&
                        suggestion?.invalid &&
                        (suggestion?.dirty || suggestion?.touched || suggestion?.untouched)
                      "
                      class="error-container"
                    >
                      Valid email is required.
                    </div>
                    <!-- <div *ngIf="submittedForm && userSelects.length === 0" class="error-container">
                      Please enter atleast one email Id.
                    </div> -->
                  </div>
                </div>
                <!-- <div class="user-chip" *ngFor="let user of userSelects; index as i">
						<div class="img-box"><img src="../../assets/user.png" /></div>
	  
						{{ user }}
						<span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
						  ><i class="fa fa-times" aria-hidden="true"></i
						></span>
					  </div> -->
                <div class="inviteUserWrapper" *ngIf="userSelects.length > 0">
                  <h4>Attendees</h4>
                  <ul class="p-0" *ngFor="let user of userSelects; index as i">
                    <li class="nav-item d-flex align-items-center" *ngIf="user.email !== profile.email">
                      <div class="attends-profile">
                        <a class="nav-link user-img">
                          <figure>
                            <img
                              src="{{ user.profilePicUrl ? user.profilePicUrl : '../../assets/user.png' }}"
                              class="img-responsive hoverChange"
                            />
                          </figure>
                          <span class="userCover" *ngIf="user.fullName">
                            <span class="user_Title"> {{ user.fullName | titlecase }}</span>
                            <span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
                              ><i class="fa fa-times"></i
                            ></span>
                          </span>
                          <span class="userCover" *ngIf="!user.fullName">
                            <span class="user_Title"> {{ user.email }}</span>
                            <span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
                              ><i class="fa fa-times"></i
                            ></span>
                          </span>
                        </a>
                      </div>
                      <div class="add-french">
                        <a href="#" class="green">
                          <i class="bi bi-plus-circle"></i>
                          <span>Add French Translator</span>
                        </a>
                      </div>
                    </li>
                    <!-- <li class="nav-item d-flex align-items-center">
                      <div class="attends-profile">
                        <a class="nav-link user-img">
                          <figure>
                            <img src="../../assets/user.png" class="img-responsive hoverChange" />
                          </figure>
                          <span class="userCover">
                            <span class="user_Title"> {{ user }}</span>
                            <span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
                              ><i class="bi bi-x"></i
                            ></span>
                          </span>
                        </a>
                      </div>
                      <div class="add-french">
                        <a href="#" class="">
                          <i class="bi bi-check2"></i>
                          <span>Finnish Translator Added</span>
                        </a>
                      </div>
                    </li> -->
                  </ul>
                </div>
                <!-- <div class="inviteUserWrapper">
                  <h4>Translators</h4>
                  <ul class="p-0" *ngFor="let user of userSelects; index as i">
                    <li class="nav-item d-flex align-items-center">
                      <div class="attends-profile">
                        <a class="nav-link user-img">
                          <figure>
                            <img src="../../assets/user.png" class="img-responsive hoverChange" />
                            <img class="language-flag" src="../../assets/language.svg" alt="" />
                          </figure>
                          <span class="userCover">
                            <span class="user_Title"> {{ user }}</span>
                            <span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
                              ><i class="bi bi-x"></i
                            ></span>
                            <p>French - English</p>
                          </span>
                        </a>
                      </div>
                      <div class="add-french">
                        <a href="#" class="green">
                          <i class="bi bi-plus-circle"></i>
                          <span>Add French Translator</span>
                        </a>
                      </div>
                    </li>
                    <li class="nav-item d-flex align-items-center">
                      <div class="attends-profile">
                        <a class="nav-link user-img">
                          <figure>
                            <img src="../../assets/user.png" class="img-responsive hoverChange" />
                            <img class="language-flag" src="../../assets/language.svg" alt="" />
                          </figure>
                          <span class="userCover">
                            <span class="user_Title"> {{ user }}</span>
                            <span *ngIf="profile.email !== user" (click)="deleteSelects(i)" class="delete-icon-chip"
                              ><i class="bi bi-x"></i
                            ></span>
                          </span>
                        </a>
                      </div>
                      <div class="add-french">
                        <a href="#" class="">
                          <i class="bi bi-check2"></i>
                          <span>Finnish Translator Added</span>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div> -->

                <!-- <div class="autocomplete-items" *ngIf="show">
					  <div *ngFor="let s of userSelects" [ngClass]="isSelected(s) ? 'selected-suggestion' : ''"
						(click)="selectSuggestion(s)">
						{{ s.emailId }}
					  </div>
					</div> -->
              </div>
            </div>
          </div>
          <div class="col-lg-12 my-4">
            <button type="submit" class="btn save-meting-btn">
              <span
                *ngIf="saveLoding"
                class="spinner-border spinner-border-sm mr-1 text-center"
                style="color: white"
              ></span
              >Save
            </button>
            <button class="btn cancel-meting-btn" (click)="cancel()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
