import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { OrganizationService } from '@app/services/organization.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-invite-people-modal',
  templateUrl: './invite-people-modal.component.html',
  styleUrls: ['./invite-people-modal.component.scss'],
})
export class InvitePeopleModalComponent implements OnInit {
  subject: Subject<boolean>;
  id: any;
  orgUrl = '';
  submitted = false;
  userSelects: any = [];
  userSelectsString = '';
  profile: any;
  loading = false;
  constructor(
    private _organizationService: OrganizationService,
    private toastr: ToastrService,
    private _auth: AuthService
  ) {
    this.profile = this.profile = this._auth.getProfileFromToken();
  }
  ngOnInit(): void {
    this.getOrganizationDetailById();
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  getOrganizationDetailById() {
    this._organizationService.getOrganizationDetailById(this.id).then((res) => {
      if (res === undefined) {
        return;
      }
      this.orgUrl = `https://organization.kiinder.com/${res.data.data.getOrganizationDetailById.manualId}`;
    });
  }
  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('LINK COPIED SUCCESSFULLY', 'Success');
  }
  async inviteMember(form: any) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }

    if (form.value) {
      if (this.userSelectsString === this.profile.email) {
        this.toastr.error('You cannot add yourself to it', 'Error');
        return;
      }
      let unique =
        this.userSelects.length > 0 && this.userSelects.find((item: any) => item.email === this.userSelectsString);

      if (unique) {
        this.toastr.error('Already added', 'Error');
        this.submitted = false;
        return;
      } else {
        let user = await this._auth.getUserByEmailId(this.userSelectsString);
        if (user) {
          this.userSelects.push({
            email: user.email,
            name: user.fullName,
            profilePicUrl: user.profilePicUrl,
          });
          this.userSelectsString = '';
          this.submitted = false;
        } else {
          this.userSelects.push({
            email: this.userSelectsString,
            name: '',
            profilePicUrl: '',
          });
          this.userSelectsString = '';
          this.submitted = false;
        }
      }
    }
  }
  deleteSelects(s: any) {
    this.userSelects = this.userSelects.filter((item: any, index: Number) => index !== s);
  }
  submit() {
    if (this.userSelects.length > 0) {
      let member = {
        organizationId: this.id,
        emails: this.userSelects,
        userId: this.profile.id,
      };
      this.loading = true;
      this._organizationService.inviteUserToOrganization(member).then((res) => {
        if (res === undefined) {
          this.loading = false;
          return;
        }
        this.loading = false;
        this.confirmMessge(true);
      });
    }
  }
}
