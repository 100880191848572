export function createOrganization(obj: any) {
  return `
  mutation{
    createOrganization(organizationName:"${obj.organizationName}"
        userId:${obj.userId}
        logo:"${obj.logo}"
        manualId:"${obj.manualId}"
        privacy:"${obj.privacy}"
    ){
      id
    organizationName
    logo
    userId
    }
  }`;
}
export function userAcceptInvitation(obj: any) {
  return `
  mutation{
    userAcceptInvitation(organizationId:${obj.organizationId}
    email:"${obj.email}"
    userId:${obj.userId}){
      message
    }
  }`;
}
export function userRejectInvitaion(obj: any) {
  return `
  mutation{
    userRejectInvitaion(organizationId:${obj.organizationId}
    email:"${obj.email}"){
      message
    }
  }`;
}
export function removeUserFromOrganisation(obj: any) {
  return `
  mutation{
    removeUserFromOrganisation(organizationId:${obj.orgId}
      removedBy:${obj.removedBy}
    id:${obj.id}){
      message
    }
  }`;
}
export function leaveOrganization(organizationId: any, userId: any) {
  return `
  mutation{
    leaveOrganization(organizationId:${organizationId}
      userId:${userId}){
      message
    }
  }`;
}
export function inviteUserToOrganization(obj: any) {
  let relatedEmail = '';
  if (obj.emails.length > 0) {
    obj.emails.forEach((element: any) => {
      relatedEmail += `{email: ${JSON.stringify(element.email)}},`;
    });
    return `
  mutation{
    inviteUserToOrganization(organizationId:${obj.organizationId}
      emails:[${relatedEmail}]
      userId:${obj.userId}
    ){
      message
    }
  }`;
  }
}
export function changeOrgMemberRole(obj: any) {
  return `mutation{
      changeOrgMemberRole(id:${obj.id},
      roleId:${obj.roleId}){
        message
      }
    }`;
}
export function updateOrganization(obj: any) {
  return `mutation{
    updateOrganization(id:${obj.id},
      organizationName:"${obj.organizationName}"
      logo:"${obj.logo}"){
        message
      }
    }`;
}
