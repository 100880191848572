<div class="row modalRow">
  <div class="col-lg-6 col-md-12 col-sm-12 sign-left-modal">
    <app-sign-up *ngIf="id === 'signUp'" (id)="getId($event)" (pass)="getPass($event)"></app-sign-up>

    <div *ngIf="id === 'logIn'">
      <div class="col-12 text-center logo-top-sign-main">
        <img class="logoImg" src="../../../assets/logo.png" />
      </div>
      <div *ngIf="!isForgot" class="">
        <div class="signUp-main">
          <h3>Log In to your account</h3>
          <form class="form-padding" [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group email-input">
              <label for="email">Email Address</label>
              <div class="input-group">
                <input type="text" formControlName="email" placeholder="Email Address" class="form-control" />
              </div>
              <div class="input-error" *ngIf="f.email.errors && f.email.errors.required && isSubmitted">
                Email is required.
              </div>
              <div class="input-error" *ngIf="f.email.errors && f.email.errors.pattern && isSubmitted">
                Enter a valid email.
              </div>
            </div>
            <div class="form-group email-input">
              <label for="password">Password</label>
              <div class="input-group">
                <input
                  [type]="type ? 'text' : 'password'"
                  formControlName="password"
                  placeholder="Password"
                  class="form-control"
                />
                <img src="../../../assets/eye.png" (click)="showPassword()" />
              </div>
              <div class="input-error" *ngIf="f.password.errors && f.password.errors.required && isSubmitted">
                Password is required.
              </div>
            </div>

            <div class="signup-prvcy-txt pb-3 pt-4">
              <a (click)="forgot()">Forgot your Password?</a>
            </div>

            <button class="btn sign-up-btn">
              <span
                *ngIf="loading"
                class="spinner-border spinner-border-sm mr-1"
                style="color: white; margin-bottom: 5px"
              ></span>
              Sign In
            </button>

            <div class="arledy-signup-txt text-center pt-3">
              Don't have an account? <a (click)="signUp('signUp')"> Sign Up</a>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="isForgot">
      <div class="col-12 mt-4 m-auto signUp-main resetPassword mb-5">
        <div class="back-btn-main mb-3">
          <a (click)="backLogin()">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="16.121" viewBox="0 0 15.5 16.121">
              <defs>
                <style>
                  .a {
                    fill: none;
                    stroke: #36b7bd;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.5px;
                  }
                </style>
              </defs>
              <g transform="translate(-4.25 -3.939)">
                <line class="a" x1="14" transform="translate(5 12)" />
                <path class="a" d="M12,19,5,12l7-7" />
              </g>
            </svg>
            Back to login</a
          >
        </div>
        <h4>Password Reset</h4>
        <p>Send a link to your email to reset the password</p>
        <form class="form-padding" #forgotPassword="ngForm" (ngSubmit)="sendLink(forgotPassword)">
          <div class="form-group email-input">
            <label for="email">Email Address</label>
            <div class="input-group">
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                class="form-control"
                required
                ngModel
                #email="ngModel"
                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
              />
            </div>
            <div
              *ngIf="submitted && email?.invalid && (email?.dirty || email?.touched || email?.untouched)"
              class="error-container"
            >
              Please enter valid Email Id.
            </div>
          </div>

          <button class="btn sign-up-btn mt-3" type="submit">
            <span
              *ngIf="sendLinkLoading"
              class="spinner-border spinner-border-sm mr-1"
              style="color: white; margin-bottom: 5px"
            ></span
            >Send Reset Link
          </button>

          <div class="arledy-signup-txt text-center pt-3">
            If you still need help, contact <a href="#"> Kiinder Support </a>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-12 col-sm-12 m-auto mb-5 sign-right-modal">
    <div class="">
      <img class="logo-sign" src="../../../assets/logo.png" />
    </div>
    <div class="">
      <img class="w-100" src="../../assets/sign-forget-img.png" />
    </div>
  </div>
</div>
