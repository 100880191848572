import { Injectable } from '@angular/core';
import { axiosPost } from './api';
import { ToastrService } from 'ngx-toastr';
import { getOrganizationRoleByOrgId, getMasterPermissions, getPermissionsByRoleId } from '../queries/rolePermissions';
import {
  addPermissionToRole,
  deletePermissionToRole,
  createOrganizationRole,
  deleteOrganizationRole,
} from '../mutations/rolePermissions';

@Injectable({
  providedIn: 'root',
})
export class RolePermssionsService {
  constructor(private toastr: ToastrService) {}
  getOrganizationRoleByOrgId(id: any) {
    try {
      return axiosPost(getOrganizationRoleByOrgId(id)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getOrganizationRoleByOrgId) {
          return response.data.data.getOrganizationRoleByOrgId;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getMasterPermissions() {
    try {
      return axiosPost(getMasterPermissions()).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getMasterPermissions) {
          return response.data.data.getMasterPermissions;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  addPermissionToRole(obj: any) {
    try {
      return axiosPost(addPermissionToRole(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.addPermissionToRole) {
          return response.data.data.addPermissionToRole;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  deletePermissionToRole(obj: any) {
    try {
      return axiosPost(deletePermissionToRole(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.deletePermissionToRole) {
          return response.data.data.deletePermissionToRole;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getPermissionsByRoleId(roleId: any) {
    try {
      return axiosPost(getPermissionsByRoleId(roleId)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getPermissionsByRoleId) {
          return response.data.data.getPermissionsByRoleId;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  createOrganizationRole(obj: any) {
    try {
      return axiosPost(createOrganizationRole(obj)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.createOrganizationRole) {
          return response.data.data.createOrganizationRole;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  deleteOrganizationRole(id: any, orgId: any) {
    try {
      return axiosPost(deleteOrganizationRole(id, orgId)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.deleteOrganizationRole) {
          return response.data.data.deleteOrganizationRole;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
}
