import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { BroadcastService } from '@app/services/broadcast.service';
import { OrganizationService } from '@app/services/organization.service';

@Component({
  selector: 'app-landing-invites',
  templateUrl: './landing-invites.component.html',
  styleUrls: ['./landing-invites.component.scss'],
})
export class LandingInvitesComponent implements OnInit {
  profile: any;
  invitations: any = [];
  loading = false;
  constructor(
    private _organizationService: OrganizationService,
    private _auth: AuthService,
    private _broadcastService: BroadcastService
  ) {
    this.profile = this._auth.getProfileFromToken();
  }

  ngOnInit(): void {
    this.getOrganizationInvitationByEmailId();
  }
  getOrganizationInvitationByEmailId() {
    this.loading = true;
    this._organizationService.getOrganizationInvitationByEmailId(this.profile.email).then((res) => {
      if (res === undefined) {
        return;
      }
      this.invitations = res.data.data.getOrganizationInvitationByEmailId;
      this.loading = false;
    });
  }
  userAcceptInvitation(organizationId: any, email: any) {
    let obj = {
      organizationId: organizationId,
      email: email,
      userId: this.profile.id,
    };
    this._organizationService.userAcceptInvitation(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.invitations = [];
      this.getOrganizationInvitationByEmailId();
      this._broadcastService.sendMessage({ type: 'invitationAccepted', message: true });
    });
  }
  userRejectInvitaion(organizationId: any, email: any) {
    let obj = {
      organizationId: organizationId,
      email: email,
    };
    this._organizationService.userRejectInvitaion(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.invitations = [];
      this.getOrganizationInvitationByEmailId();
    });
  }
  n(n: Number) {
    return n > 9 ? '' + n : '0' + n;
  }
}
