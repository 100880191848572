import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RandomIdService {
  constructor() {}
  randomNumber() {
    var first = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      first += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var second = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
      second += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var third = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 4; i++) {
      third += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    var number = `${first}-${second}-${third}`;
    return number;
  }
}
