<div class="back-btn-main mb-3">
  <a (click)="backToSignUp()">
    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="16.121" viewBox="0 0 15.5 16.121">
      <defs>
        <style>
          .a {
            fill: none;
            stroke: #36b7bd;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
          }
        </style>
      </defs>
      <g transform="translate(-4.25 -3.939)">
        <line x1="14" transform="translate(5 12)" class="a"></line>
        <path d="M12,19,5,12l7-7" class="a"></path>
      </g>
    </svg>
    Back to login</a
  >
</div>

<div class="confirm-code-heading text-center">
  <img src="../../../assets/mail.svg" alt="" />
  <h4>Enter Confirmation Code</h4>
  <br />
  <p>
    Enter the confirmation code we sent to <br />{{ email }}.
    <a (click)="resendOtp()" style="cursor: pointer">Resend Code.</a>
  </p>
</div>

<div class="verify-otp-form">
  <form #otpForm="ngForm" (ngSubmit)="verifyOtp(otpForm)">
    <div class="input-group otp-input-wrapper">
      <input
        type="text"
        name="A"
        class="form-control"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #A
        (keyup)="A.value.length == 1 ? B.focus() : ''"
        (keyup.backspace)="A.value.length == 0 ? A.focus() : ''"
      />
      <input
        type="text"
        name="B"
        class="form-control"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #B
        (keyup)="B.value.length == 1 ? C.focus() : ''"
        (keyup.backspace)="B.value.length == 0 ? A.focus() : ''"
      />
      <input
        type="text"
        name="C"
        class="form-control mr-3"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #C
        (keyup)="C.value.length == 1 ? D.focus() : ''"
        (keyup.backspace)="C.value.length == 0 ? B.focus() : ''"
      />
      <input
        type="text"
        name="D"
        class="form-control ml-3"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #D
        (keyup)="D.value.length == 1 ? E.focus() : ''"
        (keyup.backspace)="D.value.length == 0 ? C.focus() : ''"
      />
      <input
        type="text"
        name="E"
        class="form-control"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #E
        (keyup)="E.value.length == 1 ? F.focus() : ''"
        (keyup.backspace)="E.value.length == 0 ? D.focus() : ''"
      />
      <input
        type="text"
        name="F"
        class="form-control"
        maxlength="1"
        required
        ngModel
        autocomplete="off"
        #F
        (keyup.backspace)="F.value.length == 0 ? E.focus() : ''"
      />
    </div>
    <button type="submit" _ngcontent-fbd-c109="" class="btn sign-up-btn" [disabled]="!otpForm.valid">Next</button>
  </form>
</div>
