<!-- 'meeting-main' -->
<div class="homeCover">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="homeLeftCover">
          <h2 class="premiumTitle">Premium video conferencing for your business</h2>
          <p class="premiumPara">
            Sed ut perspiciatis unde amnis isto natus error sit voluptatem accusantium doloremque laudantium
          </p>
          <button class="btn skyblueBtn newMeetingBig" placement="bottom" (click)="logIn('logIn')">
            <img class="videoImg" src="../../assets/video-white.png" />
            New Meeting
          </button>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
        <div class="homeRightCover">
          <img class="meeting-img" src="../../assets/metting-img.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
