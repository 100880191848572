import { identifierName } from '@angular/compiler';

export function createContactOrg(obj: any) {
  return `
  mutation{
    createContact(fullName:"${obj.fullName}"
    phone:"${obj.phone}"
    email:"${obj.email}"
    belogsTo:"${obj.belogsTo}"
    userId:${obj.userId}
    roleId:${obj.roleId}
    organizationId: ${obj.organizationId}
   ){
      id
    }
  }`;
}
export function createContactMeta(obj: any) {
  return `
  mutation{
    createContact(fullName:"${obj.fullName}"
    phone:"${obj.phone}"
    email:"${obj.email}"
    belogsTo:"${obj.belogsTo}"
    userId:${obj.userId}
   ){
      id
    }
  }`;
}
export function editContactOrg(obj: any) {
  return `
  mutation{
    editContact(fullName:"${obj.fullName}"
    id:${obj.id}
    phone:"${obj.phone}"
    email:"${obj.email}"
    belogsTo:"${obj.belogsTo}"
    roleId:${obj.roleId}
    createdByUserId:${obj.createdByUserId}
    organizationId: ${obj.organizationId}
   ){
      message
    }
  }`;
}
export function editContactMeta(obj: any) {
  return `
  mutation{
    editContact(fullName:"${obj.fullName}"
    id:${obj.id}
    phone:"${obj.phone}"
    email:"${obj.email}"
    belogsTo:"${obj.belogsTo}"
    createdByUserId:${obj.createdByUserId}
   ){
      message
    }
  }`;
}
export function deleteContact(id: any) {
  return `
  mutation{
    deleteContact(id:${id}
   ){
      message
    }
  }`;
}
