import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-all-metting',
  templateUrl: './landing-all-metting.component.html',
  styleUrls: ['./landing-all-metting.component.scss'],
})
export class LandingAllMettingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
