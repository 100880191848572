import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '@app/services/organization.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { KinderSpacingComponent } from '../kinder-spacing/kinder-spacing.component';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-front-invitations',
  templateUrl: './front-invitations.component.html',
  styleUrls: ['./front-invitations.component.scss'],
})
export class FrontInvitationComponent implements OnInit {
  profile: any;
  invitations: any = [];
  modalRef: BsModalRef;

  constructor(
    private _auth: AuthService,
    private _organizationService: OrganizationService,
    private modalService: BsModalService,
    private router: Router
  ) {
    this.profile = this._auth.getProfileFromToken();
  }
  ngOnInit(): void {
    this.getOrganizationInvitationByEmailId();
  }
  getOrganizationInvitationByEmailId() {
    this._organizationService.getOrganizationInvitationByEmailId(this.profile.email).then((res) => {
      if (res === undefined) {
        return;
      }

      this.invitations = res.data.data.getOrganizationInvitationByEmailId;
    });
  }
  userAcceptInvitation(organizationId: any, email: any) {
    console.log('organizationId', organizationId, email);
    let obj = {
      organizationId: organizationId,
      email: email,
      userId: this.profile.id,
    };
    this._organizationService.userAcceptInvitation(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.invitations = [];
      this.getOrganizationInvitationByEmailId();
    });
  }
  userRejectInvitaion(organizationId: any, email: any) {
    let obj = {
      organizationId: organizationId,
      email: email,
    };
    this._organizationService.userRejectInvitaion(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.invitations = [];
      this.getOrganizationInvitationByEmailId();
    });
  }
  n(n: Number) {
    return n > 9 ? '' + n : '0' + n;
  }
  createOrganization() {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(KinderSpacingComponent, {
      class: 'modal-lg-login modal-dialog-centered signupModal',
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  skipStep() {
    this.router.navigate(['/landing/contacts']);
  }
}
