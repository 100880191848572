import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  isTokenExpire = false;
  profile: any;
  profilePic: any = '';
  constructor(private router: Router, private authService: AuthService) {
    this.profile = this.authService.getProfileFromToken();
    this.profilePic = this.profile.profilePicUrl;
  }

  ngOnInit() {
    this.isTokenExpire = this.authService.isTokenExpired();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/dashboard']);
  }

  get username(): string | null {
    const credentials = this.authService.getProfileFromToken();
    return credentials ? credentials.email : null;
  }
}
