<div class="modal-body home-modal-bdy permission-req-modal row">
  <div class="home-modal-hdr col-11">
    <h4 class="modal-title">Preferences</h4>
    <p class="subheadingnew">Sed ut perspiciatis unde omnis iste natus error sit</p>
  </div>
  <button type="button" class="close pull-right col-1" aria-label="Close" (click)="confirmMessge(false)">
    <img src="../../assets/x-circle.png" />
  </button>

  <div class="col-12">
    <form #yourName="ngForm" (ngSubmit)="submit(yourName)">
      <div class="justify-content-around mb-3">
        <div class="form-group">
          <label for="name">Your Name</label>
          <input
            type="text"
            class="form-control"
            name="name"
            placeholder="Your Name"
            [(ngModel)]="name"
            required
            #nameReq="ngModel"
          />
          <div
            *ngIf="submitted && nameReq?.invalid && (nameReq?.dirty || nameReq?.touched || nameReq?.untouched)"
            class="error-container"
          >
            Name is required.
          </div>
        </div>

        <!-- <div class="form-group">
    <label for="name">Mode of Communication?</label>
    <select class="form-control">
      <option>English</option>
      <option>American Sign Language</option>
      <option>German</option>
      <option>French</option>
      <option>Polish</option>
      <option>Korean</option>
    </select>
  </div>
  <div class="form-group">
    <legend class="col-form-label pt-0">Would you like a translator for this meeting.</legend>
    <div class="d-flex">
      <div class="form-check mr-4">
        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" checked />
        <label class="form-check-label" for="gridRadios1"> Yes </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="option2" />
        <label class="form-check-label" for="gridRadios2"> No </label>
      </div>
    </div>
  </div> -->
      </div>
      <div class="home-modal-btns text-center mt-5">
        <button class="btn meating-cncl-btn mb-2 Close w-100" aria-label="Close" type="submit">Continue</button>
        <br />
        <button class="btn cancelBtn w-100" aria-label="Close" (click)="confirmMessge(false)">Cancel</button>
      </div>
    </form>
  </div>
</div>
