<div class="dashLeftCover">
  <ul>
    <li routerLinkActive #rla="routerLinkActive" [routerLinkActive]="['active', '']">
      <a href="#" [routerLink]="['../home']">
        <img src="../../../assets/home.svg" class="dashLeftImg" />
        <span class="sideLink">Home</span>
      </a>
    </li>
    <li routerLinkActive #rla="routerLinkActive" [routerLinkActive]="['active', '']">
      <a [routerLink]="['../chat']">
        <img src="../../../assets/chat.svg" class="dashLeftImg" />
        <span class="sideLink">Chat</span>
      </a>
    </li>
    <li routerLinkActive #rla="routerLinkActive" [routerLinkActive]="['active', '']">
      <a href="#">
        <img src="../../../assets/calendar.svg" class="dashLeftImg" />
        <span class="sideLink">Calendar</span>
      </a>
    </li>
    <li routerLinkActive #rla="routerLinkActive" [routerLinkActive]="['active', '']">
      <a href="#">
        <img src="../../../assets/calendar.svg" class="dashLeftImg" />
        <span class="sideLink">Contacts</span>
      </a>
    </li>
    <li routerLinkActive #rla="routerLinkActive" [routerLinkActive]="['active', '']">
      <a href="#">
        <img src="../../../assets/calendar.svg" class="dashLeftImg" />
        <span class="sideLink">History</span>
      </a>
    </li>
  </ul>
</div>
