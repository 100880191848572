import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  createContactMeta,
  createContactOrg,
  editContactOrg,
  editContactMeta,
  deleteContact,
} from '../mutations/contacts';
import {
  getContactByUserIdMeta,
  // getContactByUserIdOrg,
  getContactById,
  getAllContactByOrganisationId,
} from '../queries/contacts';
import { axiosPost } from './api';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(private toastr: ToastrService) {}
  createContact(obj: any) {
    try {
      if (obj.organizationId) {
        return axiosPost(createContactOrg(obj)).then((response: any) => {
          if (response.data.errors && response.data.errors.length > 0) {
            this.toastr.error(response.data.errors[0].message);
            return;
          }
          if (response.data.data.createContact) {
            return response;
          }
        });
      } else {
        return axiosPost(createContactMeta(obj)).then((response: any) => {
          if (response.data.errors && response.data.errors.length > 0) {
            this.toastr.error(response.data.errors[0].message);
            return;
          }
          if (response.data.data.createContact) {
            return response;
          }
        });
      }
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  editContact(obj: any) {
    try {
      if (obj.organizationId) {
        return axiosPost(editContactOrg(obj)).then((response: any) => {
          if (response.data.errors && response.data.errors.length > 0) {
            this.toastr.error(response.data.errors[0].message);
            return;
          }
          if (response.data.data.editContact) {
            return response;
          }
        });
      } else {
        return axiosPost(editContactMeta(obj)).then((response: any) => {
          if (response.data.errors && response.data.errors.length > 0) {
            this.toastr.error(response.data.errors[0].message);
            return;
          }
          if (response.data.data.editContact) {
            return response;
          }
        });
      }
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getContactByUserIdMeta(userId: any, searchText: any) {
    try {
      return axiosPost(getContactByUserIdMeta(userId, searchText)).then((response: any) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getContactByUserIdMeta) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  // getContactByUserIdOrg(userId: any, organizationId: any) {
  //   try {
  //     return axiosPost(getContactByUserIdOrg(userId, organizationId)).then((response: any) => {
  //       if (response.data.errors && response.data.errors.length > 0) {
  //         this.toastr.error(response.data.errors[0].message);
  //         return;
  //       }
  //       if (response.data.data.getContactByUserIdOrg) {
  //         return response;
  //       }
  //     });
  //   } catch (error) {
  //     this.toastr.error(error.message);
  //   }
  // }
  getContactById(id: any) {
    try {
      return axiosPost(getContactById(id)).then((response: any) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getContactById) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getAllContactByOrganisationId(id: any, userId: any, text: any) {
    try {
      return axiosPost(getAllContactByOrganisationId(id, userId, text)).then((response: any) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getAllContactByOrganisationId) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  deleteContact(id: any) {
    try {
      return axiosPost(deleteContact(id)).then((response: any) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.deleteContact) {
          return response;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
}
