import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { KinderSpacingComponent } from '../../kinder-spacing/kinder-spacing.component';
import { OrganizationService } from '@app/services/organization.service';
import * as $ from 'jquery';
import { BroadcastService } from '@app/services/broadcast.service';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-organization-landing',
  templateUrl: './organization-landing.component.html',
  styleUrls: ['./organization-landing.component.scss'],
})
export class OrganizationLandingComponent implements OnInit {
  modalRef: BsModalRef;
  item: any;
  profile: any;
  profileImg = '../../../assets/avater-profile.png';
  profileTool = '';
  isProfile = true;
  subscription: any;
  activeId: string;
  constructor(
    private router: Router,
    private _organizationService: OrganizationService,
    private _broadcastService: BroadcastService,
    private modalService: BsModalService,
    private _auth: AuthService
  ) {
    this.profile = this._auth.getProfileFromToken();
    this.getDefaultUser(this.profile.id);
    this.subscription = this._broadcastService.getMessage().subscribe((message: any) => {
      switch (message.type) {
        case 'invitationAccepted':
          if (message.message) {
            this.getOrganizations();
          }
          break;
        case 'leaveOrganization':
          if (message.message) {
            this.getOrganizations();
          }
          break;
        case 'updateOrg':
          if (message.message) {
            this.getOrganizations();
          }
          break;
        case 'updateProfile':
          if (message.message) {
            this.getDefaultUser(this.profile.id);
          }
          break;
      }
    });
  }
  orgs: any = [];
  ngOnInit() {
    let href = this.router.url.split('/')[3];
    if (href) {
      let firstChange = href.replace('%3D', '=');
      let secondChange = firstChange.replace('%3D', '=');
      this.activeId = window.atob(secondChange);
    }
    this.getOrganizations();
  }
  getDefaultUser(id: any) {
    this._auth.user(id).then((user: any) => {
      this.profileImg = user.profilePicUrl ? user.profilePicUrl : '../../../assets/avater-profile.png';
      this.profileTool = `${user.firstName}'s Kiinder Space`;
    });
  }
  getOrganizations() {
    this._organizationService.getOrganizationDetailByUserId(this.profile.id).then((res) => {
      if (res === undefined) {
        return;
      }
      this.orgs = res.data.data.getOrganizationDetailByUserId;
      console.log('this.orgs', this.orgs);
      if (this.activeId) {
        setTimeout(() => {
          this.byDefultActive(this.activeId);
        }, 100);
      }
    });
  }
  byDefultActive(id: any) {
    $('.sideBarMenu li').removeClass('active');
    $(`#${id}`).addClass('active');
  }
  routeOrganization(organization: any) {
    $('.sideBarMenu li').on('click', function () {
      $('.sideBarMenu li').removeClass('active');
      $(this).addClass('active');
    });
    this.item = organization;
    let id = window.btoa(organization.Organization.id);
    this.router.navigate([`/landing/org/${id}/meetings/${id}`]);
  }
  profileClick() {
    $('.sideBarMenu li').on('click', function () {
      $('.sideBarMenu li').removeClass('active');
      $(this).addClass('active');
    });

    this.router.navigate(['/landing/contacts']);
    this.item = undefined;
  }

  createOrganization() {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(KinderSpacingComponent, {
      class: 'modal-lg-login modal-dialog-centered signupModal',
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.getOrganizations();
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  logoCheck(logo: any) {
    console.log('logog', logo);
    if (logo !== 'undefined') {
      return logo;
    } else {
      return '../../../assets/organizationAvtar.png';
    }
  }
}
