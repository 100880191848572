export function getOrganizationRoleByOrgId(organizationId: any) {
  return `query{
    getOrganizationRoleByOrgId(organizationId:${organizationId}){
     id
     roleName
     organizationId
   }
   }`;
}
export function getMasterPermissions() {
  return `query{
    getMasterPermissions{
      id
      name
      groupType
    }
  }`;
}
export function getPermissionsByRoleId(roleId: any) {
  return `query{
    getPermissionsByRoleId(roleId:${roleId}){
      id
      organizationRoleId
      masterPermissionId
    }
  }`;
}
