export function registerMutation(obj: any) {
  return `
    mutation {
      register(
        userName:"${obj.userName}"
        firstName: "${obj.firstName}"
        lastName: "${obj.lastName}"
        email: "${obj.email}"
        password: "${obj.password}"
      ) {
        user {
          id
          email
          firstName
          lastName
          isActive
        }
      }
    }`;
}
export function updateUser(obj: any) {
  return `
  mutation{
    updateUser(id:${obj.id}
    firstName:"${obj.firstName}"
    lastName:"${obj.lastName}"
    gender:"${obj.gender}"
    firstLanguage:"${obj.firstLanguage}"
    otherLanguage:"${obj.otherLanguage}"
    profilePicUrl:"${obj.profilePicUrl}"
    fullName:"${obj.fullName}"
    ){
      id
    }
  }`;
}
export function changePassword(obj: any) {
  return `mutation{
    changePassword(id:${obj.id}
    oldPassword:"${obj.oldPassword}"
    newPassword:"${obj.newPassword}"){
      message
    }
  }`;
}
export function logInMutation(obj: any) {
  return `
    mutation {
      login(
        email: "${obj.email}"
        password: "${obj.password}"
      ) {
        token
        user {
          id
          email
          fullName
          firstName
          lastName
          profilePicUrl
          firstLanguage
          role
          isActive
        }
      }
    }`;
}
export function confirmOtp(obj: any) {
  return `
  mutation{
    confirmOtp(email:"${obj.email}"
    otp: ${obj.otp}
    ){
      user{
        id
        firstName
        lastName
      }
    }
  }`;
}
export function resendOtp(email: any) {
  return `mutation{
    resendOtp(email:"${email}"){
      message
    }
  }`;
}
export function forgotPassword(email: any) {
  return `mutation{
    forgotPassword(email:"${email}"){
    message
  }}`;
}
export function resetPassword(obj: any) {
  return `mutation{
    resetPassword(token:"${obj.token}"
    newPassword:"${obj.password}"){
      message
    }
  }`;
}

export function onBoardingMutation(obj: any) {
  return `
  mutation{
    onBoardUser(id: ${obj.id} 
      presonalAddress:{
      address:"${obj.presonalAddress.address}"
      country:"${obj.presonalAddress.country}"
      state:"${obj.presonalAddress.state}"
      postal_code:"${obj.presonalAddress.postal_code}"
      type:"${obj.presonalAddress.type}"
    }
    billingAddress:{
       address:"${obj.billingAddress.address}"
      country:"${obj.billingAddress.country}"
      state:"${obj.billingAddress.state}"
      postal_code:"${obj.billingAddress.postal_code}"
      type:"${obj.billingAddress.type}"
    }
    fullName:"${obj.fullName}"
    gender:"${obj.gender}"
    profilePicUrl:"${obj.profilePicUrl}"
    role:"${obj.role}"
    firstLanguage:"${obj.firstLanguage}"
    otherLanguage:"${obj.otherLanguage}"
    privacySetting:"${obj.privacySetting}"
    contactPreferences:"${obj.contactPreferences}"){
      token
      user{
        id
    firstName
    lastName
    fullName
    firstLanguage
    profilePicUrl
    role
    email
    isActive
      }
    }
  }`;
}
export function muteAndUnmuteNotificationsForUser(obj: any) {
  return `mutation{
    muteAndUnmuteNotificationsForUser(id:${obj.id}
    isNotifications:${obj.isNotifications}){
      message
    }
  } `;
}
export function changePrivacy(obj: any) {
  return `mutation{
    changePrivacy(id:${obj.id}
      privacySetting:"${obj.privacySetting}"){
      message
    }
  } `;
}
