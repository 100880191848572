import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewMeetingComponent } from '../meeting-module/new-meeting/new-meeting.component';
import { EditMeetingComponent } from '../meeting-module/edit-meeting/edit-meeting.component';
import { Authorization } from '../helpers/authorization';

const routes: Routes = [
  {
    path: '',
    component: NewMeetingComponent,
    canActivate: [Authorization],
  },
  {
    path: ':id',
    component: EditMeetingComponent,
    canActivate: [Authorization],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetingRoutingModule {}
