<div class="landing-page-title">
  <h2>All Meetings</h2>
</div>

<div class="landing-page-container">
  <div class="upcomming-meeting-heading">
    <h2>Upcoming Meetings</h2>
  </div>
  <div class="upcomming-meeting-list-wrapper">
    <div class="upcomming-meeting-list">
      <h3>Today</h3>
      <div class="upcomming-meeting-list-wrap d-flex align-items-start flex-wrap">
        <span class="meeting-number">01</span>
        <div class="upcomming-meeting-content d-flex align-items-start">
          <span class="upcomming-meeting-time">10:00 AM</span>
          <div class="upcomming-meeting-content-wrap">
            <h4>Customer Support</h4>
            <p>10:00 AM - 11:00 AM</p>
            <span>Host: You</span>
          </div>
        </div>
        <a href="#" class="join-start-meeting">Start Meeting</a>
        <a href="#" class="edit-meeting"><i class="bi bi-pencil-square"></i></a>
      </div>
    </div>
    <div class="upcomming-meeting-list">
      <h3>Tomorrow</h3>
      <div class="upcomming-meeting-list-wrap d-flex align-items-start flex-wrap">
        <span class="meeting-number">02</span>
        <div class="upcomming-meeting-content d-flex align-items-start">
          <span class="upcomming-meeting-time">11:00 AM</span>
          <div class="upcomming-meeting-content-wrap">
            <h4>Event Planning with the team</h4>
            <p>10:00 AM - 11:00 AM</p>
            <span>Host: Alexander Young</span>
          </div>
        </div>
        <a href="#" class="join-start-meeting">Join Meeting</a>
      </div>
    </div>
    <div class="upcomming-meeting-list">
      <h3>Jun 21, 2021</h3>
      <div class="upcomming-meeting-list-wrap d-flex align-items-start flex-wrap">
        <span class="meeting-number">03</span>
        <div class="upcomming-meeting-content d-flex align-items-start">
          <span class="upcomming-meeting-time">08:00 PM</span>
          <div class="upcomming-meeting-content-wrap">
            <h4>Discussion on Global Warming</h4>
            <p>12:00 AM - 12:30 AM</p>
            <span>Host: You</span>
          </div>
        </div>
        <a href="#" class="join-start-meeting">Start Meeting</a>
        <a href="#" class="edit-meeting"><i class="bi bi-pencil-square"></i></a>
      </div>
    </div>
  </div>
</div>
