<header>
  <nav class="navbar navbar-expand-lg navbar-light customNavbar">
    <div class="container-fluid">
      <a class="navbar-brand">
        <img src="../../../assets/logo.svg" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        aria-controls="navbar-menu"
        aria-label="Toggle navigation"
        (click)="toggleMenu()"
        [attr.aria-expanded]="!menuHidden"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbar-menu" class="collapse navbar-collapse customColl" [ngbCollapse]="menuHidden">
        <ul class="navbar-nav ml-auto customdashLink">
          <li class="nav-item">
            <a class="nav-link customBageLink" href="#">
              <img src="../../../assets/bell.svg" alt="Please check your internet connection" />
              <span class="badge badge-danger badgecustom"></span
            ></a>
          </li>
          <li class="nav-item" ngbDropdown>
            <a class="nav-link user-img" ngbDropdownToggle>
              <img src="{{ profilePic ? profilePic : '../../../assets/avater-profile.png' }}" alt="" />
              <span class="userCover">
                <span class="user_Title"> {{ profile.firstName | titlecase }}</span>
                <img src="../../../assets/chevron-down.svg" alt="" />
              </span>
            </a>
            <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-right">
              <h6 class="dropdown-header">
                <span translate>Logged in as</span> <b>{{ username }}</b>
              </h6>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="logout()" translate>Logout</button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
