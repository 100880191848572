<div class="file-upload">
  <h3>Crop Image</h3>
  <!-- <span class="text">upload</span>
    <input id="custom-input" type="file" (change)="fileChangeListener($event)" /> -->
  <label class="btn-bs-file btn btn-lg">
    Browse
    <input id="custom-input" type="file" accept=".jpg, .jpeg" (change)="fileChangeListener($event)" />
  </label>
</div>
<img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>

<div class="d-flex justify-content-end m-3 form-group-btn">
  <button type="submit" class="btn btn-save" (click)="save()">Save</button>
  <button type="button" class="btn btn-cancel" (click)="confirmMessge(false)" (click)="confirmMessge(false)">
    Cancel
  </button>
</div>
