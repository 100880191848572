import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MeetingService } from '@app/services/meeting.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import * as moment from 'moment';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-meeting',
  templateUrl: './edit-meeting.component.html',
  styleUrls: ['./edit-meeting.component.scss'],
})
export class EditMeetingComponent implements OnInit {
  meeting: any;
  meetingUrl: any;
  newMeeting = {
    id: 0,
    meetingManualId: '',
    userId: '',
    title: '',
    durationTime: {
      hour: 0,
      minutes: 0,
    },
    startTime: '',
    attendess: '',
  };
  meridian = true;
  hours = [
    { hour: 1 },
    { hour: 2 },
    { hour: 3 },
    { hour: 4 },
    { hour: 5 },
    { hour: 6 },
    { hour: 7 },
    { hour: 8 },
    { hour: 9 },
    { hour: 10 },
    { hour: 11 },
    { hour: 12 },
  ];
  attendess: any = [];
  minutes: any = [];
  userSelects: any = [];
  userSelectsString = '';
  submitted = false;
  submittedForm = false;
  profile: any;
  saveLoding = false;
  defultDate = {
    day: 0,
    month: 0,
    year: 0,
  };
  defulTime = {
    hour: 0,
    minute: 0,
  };
  chooseDate: any;
  isFutureTime = false;
  isTime = false;
  loading = false;
  constructor(
    private route: ActivatedRoute,
    private config: NgbDatepickerConfig,
    private meetingService: MeetingService,
    private toastr: ToastrService,
    private location: Location,
    private _auth: AuthService
  ) {
    this.profile = this._auth.getProfileFromToken();
    this.route.params.subscribe((params) => {
      let id = window.atob(params.id);
      this.getMeetingByMeetingId(id);
    });
    const current = new Date();
    config.minDate = { year: current.getFullYear(), month: current.getMonth() + 1, day: current.getDate() };
  }

  ngOnInit(): void {
    this.getMinutes();
  }
  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  getMeetingByMeetingId(id: any) {
    this.meetingService
      .getMeetingByMeetingId(id)
      .then((meeting: any) => {
        this.meeting = meeting.data.data.getMeetingByMeetingId;
        this.meetingUrl = `https://meeting.kiinder.com/${this.meeting.meetingManualId}`;
        console.log('meetingby id', this.meeting);
        this.valuePoluted(meeting.data.data.getMeetingByMeetingId);
      })
      .catch((err) => {
        console.log('err---', err);
      });
  }
  valuePoluted(meet: any) {
    let newDate = new Date(Number(meet.startTime));
    this.defultDate = {
      day: newDate.getDate(),
      month: newDate.getMonth() + 1,
      year: newDate.getFullYear(),
    };
    this.defulTime = {
      hour: newDate.getHours(),
      minute: newDate.getMinutes(),
    };
    this.newMeeting = {
      id: meet.id,
      meetingManualId: meet.meetingManualId,
      userId: meet.userId,
      title: meet.title,
      durationTime: {
        hour: meet.durationTime.hour,
        minutes: meet.durationTime.minutes,
      },
      startTime: '',
      attendess: '',
    };
    this.userSelects = meet.attendees;
  }
  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.toastr.success('LINK COPIED SUCCESSFULLY', 'Success');
  }
  getMinutes() {
    for (var i = 1; i <= 12; i++) {
      var numString = (i * 5).toString();
      var paddedNum = numString.padStart(2, '0');
      this.minutes.push({
        minute: paddedNum,
        value: i * 5,
      });
    }
  }
  async addAttendees(form: any) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    if (form.value) {
      if (this.userSelectsString === this.profile.email) {
        this.toastr.error('You cannot add yourself to it', 'Error');
        return;
      }
      let unique =
        this.userSelects.length > 0 && this.userSelects.find((item: any) => item.email === this.userSelectsString);

      if (unique) {
        this.toastr.error('Already added', 'Error');
        this.submitted = false;
        return;
      } else {
        console.log('this.userSelectsString', this.userSelectsString);
        let user = await this._auth.getUserByEmailId(this.userSelectsString);
        console.log('user', user);
        if (user) {
          this.userSelects.push({
            email: user.email,
            fullName: user.fullName,
            profilePicUrl: user.profilePicUrl,
          });
          this.userSelectsString = '';
          this.submitted = false;
        } else {
          this.userSelects.push({
            email: this.userSelectsString,
            fullName: '',
            profilePicUrl: '',
          });
          this.userSelectsString = '';
          this.submitted = false;
        }
        console.log(' this.userSelects', this.userSelects);
      }
    }
  }
  cancel() {
    this.location.back();
  }
  deleteSelects(s: any) {
    this.userSelects = this.userSelects.filter((item: any, index: Number) => index !== s);
  }
  submitForm(event: any) {
    this.submittedForm = true;
    if (!event.valid) {
      return;
    }
    let year = event.value.startDate.year;
    let month = event.value.startDate.month - 1;
    let day = event.value.startDate.day <= 9 ? '0' + event.value.startDate.day : event.value.startDate.day;
    let hour = event.value.startTime.hour ? event.value.startTime.hour : 0;
    let minute = event.value.startTime.minute ? event.value.startTime.minute : 0;
    this.newMeeting.startTime = new Date(year, month, day, hour, minute).toISOString();
    this.newMeeting.durationTime.hour = Number(event.value.durationHour) ? Number(event.value.durationHour) : 0;
    this.newMeeting.durationTime.minutes = Number(event.value.durationMinute) ? Number(event.value.durationMinute) : 0;
    this.newMeeting.attendess = this.userSelects;
    if (this.userSelects.length > 1) {
      this.saveLoding = true;
      this.meetingService
        .updateMeeting(this.newMeeting)
        .then((response) => {
          if (response.data.errors) {
            this.toastr.error(response.data.errors[0].message);
            this.saveLoding = false;
            return;
          }
          this.saveLoding = false;
          this.toastr.success(`Meeting Successfully Updated`, 'Success');
          this.location.back();
        })
        .catch((error) => {
          this.saveLoding = false;
          this.toastr.error(error.message);
        });
    } else {
      this.saveLoding = false;
      this.toastr.error(`Please add atleast one attendee.`, 'Error');
    }
  }
  checkDate(event: any) {
    let date = new Date(event?.year, event?.month - 1, event?.day);
    let currentDate = new Date();
    this.chooseDate = moment(date).format('DD-MM-YYYY');
    if (date > currentDate) {
      this.isFutureTime = false;
    }
    this.isTime = false;
  }
  checkTime(event: any) {
    debugger;
    if (event !== null) {
      let d = new Date();
      d.setHours(event.hour);
      d.setMinutes(event.minute);
      let currentDate = moment().format('DD-MM-YYYY');
      let currentTime = new Date();
      if (this.chooseDate == currentDate) {
        if (d < currentTime) {
          this.isFutureTime = true;
        } else {
          this.isFutureTime = false;
        }
      }
    }
  }
}
