import { EventEmitter, Injectable } from '@angular/core';
import { axiosPost } from './api';
import { getChatToken, getAccountChannel, getInvitedMember, getChannelDetail } from '../queries/chat';
import { ToastrService } from 'ngx-toastr';
import { localStorageKeys } from '../helpers/index';
import * as Twilio from 'twilio-chat';
import Client from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';
import { Router } from '@angular/router';
import { Helper } from '../helpers/index';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public chatClient: Client;
  public currentChannel: Channel;
  public chatConnectedEmitter: EventEmitter<any> = new EventEmitter<any>();
  public chatDisconnectedEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toastr: ToastrService, private router: Router) {}

  getChatToken(username: any) {
    try {
      return axiosPost(getChatToken(username)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getTwilioChatToken && response.data.data.getTwilioChatToken.token) {
          this.setToken(response.data.data.getTwilioChatToken.token);
          return response.data.data.getTwilioChatToken.token;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  getVideoChatToken(username: any) {
    try {
      return axiosPost(getChatToken(username)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getTwilioChatToken && response.data.data.getTwilioChatToken.token) {
          return response.data.data.getTwilioChatToken.token;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  setToken(token: any) {
    localStorage.setItem(localStorageKeys.TwilioChatToken, token);
  }
  removeToken() {
    localStorage.removeItem(localStorageKeys.TwilioChatToken);
  }
  refreshToken() {
    try {
      let profile = JSON.parse(localStorage.getItem('profile'));
      let fullIdentity = `${profile.email}-${profile.fullName}`;
      axiosPost(getChatToken(fullIdentity)).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getTwilioChatToken && response.data.data.getTwilioChatToken.token) {
          this.setToken(response.data.data.getTwilioChatToken.token);
          return;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  connectForChat(token: any) {
    Twilio.Client.create(token)
      .then(async (client: Client) => {
        this.chatClient = client;
        this.chatConnectedEmitter.emit(true);
      })
      .catch(async (err: any) => {
        if (err.message.indexOf('token is expired')) {
          this.removeToken();
          this.refreshToken();
        }
        this.chatDisconnectedEmitter.emit(true);
      });
  }

  getAccountChannel() {
    try {
      return axiosPost(getAccountChannel()).then((response) => {
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getAllChannels) {
          return response.data.data.getAllChannels;
        }
      });
    } catch (error) {
      this.toastr.error(error.message);
    }
  }
  getPublicChannels() {
    return this.chatClient.getUserChannelDescriptors();
  }

  getChannel(sid: string) {
    let response = this.chatClient.getChannelBySid(sid);
    return response;
  }

  createChannel(friendlyName: string, isPrivate: boolean = false, attributes: any = {}) {
    console.log('friendlyName', friendlyName, 'isPrivate', isPrivate, 'attributes', attributes);
    try {
      let response = this.chatClient.createChannel({
        friendlyName: friendlyName,
        isPrivate: isPrivate,
        // uniqueName: Helper.guid(),
        uniqueName: friendlyName,
        attributes: attributes,
      });
      return response;
    } catch (error) {}
  }
  getChannelDetail(uniqueName: string) {
    try {
      return axiosPost(getChannelDetail(uniqueName)).then((response) => {
        console.log(response, 'response');
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getChannelDetail) {
          return response.data.data.getChannelDetail;
        }
      });
    } catch (err) {
      console.log('error = getinvited member', err);
    }
  }
  getInvitedMember(sid: any) {
    try {
      return axiosPost(getInvitedMember(sid)).then((response) => {
        console.log(response, 'response');
        if (response.data.errors && response.data.errors.length > 0) {
          this.toastr.error(response.data.errors[0].message);
          return;
        }
        if (response.data.data.getInvitedMember) {
          return response.data.data.getInvitedMember;
        }
      });
    } catch (err) {
      console.log('error = getinvited member', err);
    }
  }
}
