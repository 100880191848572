<div class="add-contact-popup-wrapper">
  <div class="add-contact-popup-head d-flex justify-content-between">
    <h3>Invite People</h3>
  </div>
  <div class="kinder-space-model-content">
    <form>
      <div class="setup-profile-form">
        <div class="copyurlWrapper">
          <input class="form-control bg-gray" type="text" name="copy" [(ngModel)]="orgUrl" #userinput readonly />
          <img
            class="copyImg"
            src="../../assets/copy.png"
            style="cursor: pointer"
            (click)="copyInputMessage(userinput)"
          />
        </div>
        <div class="anyone-join-wrap d-flex align-items-center">
          <i class="bi bi-lock"></i>
          <div class="dropdown">
            <select class="form-control dropdown-toggle privacyOrg" id="selectControl">
              <option value="public">Anyone with this link can join</option>
              <option value="private">Only invited people can join</option>
            </select>
          </div>

          <!-- <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <p>Anyone with this link can join <span class="bi bi-chevron-down"></span></p>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </div> -->
        </div>

        <div class="form-group">
          <label>Invite Members</label>
          <input
            type="text"
            class="form-control"
            name="inviteMember"
            placeholder="Enter email Id"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            [(ngModel)]="userSelectsString"
            placeholder="Email"
            #suggestion="ngModel"
            (keyup.enter)="inviteMember(suggestion)"
          />
          <div
            *ngIf="
              submitted && suggestion?.invalid && (suggestion?.dirty || suggestion?.touched || suggestion?.untouched)
            "
            class="error-container"
          >
            Valid email is required.
          </div>
          <div *ngIf="submitted && userSelects.length === 1" class="error-container">
            Please enter atleast one email Id.
          </div>
        </div>

        <div class="invite-members-listing d-flex flex-wrap">
          <div class="invite-members-wrap" *ngFor="let user of userSelects; index as i">
            <figure>
              <img src="{{ user.profilePicUrl ? user.profilePicUrl : '../../assets/male_avatar-512.svg' }}" alt="" />
            </figure>
            <p *ngIf="user.name">{{ user.name | titlecase }}</p>
            <p *ngIf="!user.name">{{ user.email }}</p>
            <i class="bi bi-x" (click)="deleteSelects(i)"></i>
          </div>
        </div>
        <div class="continue-btn">
          <button type="button" class="cancel-btn" (click)="confirmMessge(false)">Cancel</button>
          <button type="button" class="btn" (click)="submit()" [disabled]="userSelects.length === 0">
            <span
              *ngIf="loading"
              class="spinner-border spinner-border-sm mr-2"
              style="color: white; margin-bottom: 5px"
            ></span
            >Invite
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
