import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MeetingRoutingModule } from './meeting-routing.module';
import { NewMeetingComponent } from './new-meeting/new-meeting.component';
import { EditMeetingComponent } from './edit-meeting/edit-meeting.component';

@NgModule({
  declarations: [NewMeetingComponent, EditMeetingComponent],
  imports: [CommonModule, MeetingRoutingModule, FormsModule, ReactiveFormsModule, NgbModule],
})
export class MeetingModule {}
