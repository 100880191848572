import { Component, OnInit, TemplateRef, ViewChild, NgZone } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { MeetingService } from '../services/meeting.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { PermissionComponent } from '../modals/permission/permission.component';
import { PreferencesComponent } from '../modals/preferences/preferences.component';
import * as moment from 'moment';
import { AuthService } from '@app/services/auth.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  host: { class: 'appHome', width: '100%' },
})
export class HomeComponent implements OnInit {
  @ViewChild('permissions') permissions: TemplateRef<any>;
  modalRef: BsModalRef;
  modalRefnew: BsModalRef;
  bsModalRef: BsModalRef;
  bsModalRefPre: BsModalRef;
  modalRefLink: BsModalRef;
  quote: string | undefined;
  isLoading = false;
  loading = false;
  meetingUrl: any;
  profile: any;
  meetingId = {
    id: '',
  };
  newLink = {
    link: '',
  };
  isLink = true;
  isMeetingId: boolean = false;
  today: any = [];
  tomorrow: any = [];
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private meetingService: MeetingService,
    public zone: NgZone,
    private _auth: AuthService
  ) {
    this.profile = this.profile = this._auth.getProfileFromToken();
  }

  ngOnInit() {
    this.isLoading = true;
    this.getAllMeetings();
  }
  getAllMeetings() {
    this.loading = true;
    this.meetingService
      .getMeetingsByUserId(this.profile.email, '')
      .then((meetings) => {
        if (meetings.data.data.getMeetingsByUserId === null) {
          this.loading = false;
          return;
        }
        this.today = meetings.data.data.getMeetingsByUserId.filter(
          (obj: any) =>
            moment(new Date(Number(obj.startTime))).format('YYYY MM DD') === moment().format('YYYY MM DD') &&
            this.compareTime(obj.startTime, obj.durationTime) > new Date().getTime()
        );
        this.tomorrow = meetings.data.data.getMeetingsByUserId.filter(
          (obj: any) =>
            moment(new Date(Number(obj.startTime))).format('YYYY MM DD') ===
            moment().add(1, 'days').format('YYYY MM DD')
        );

        this.loading = false;
      })
      .catch((error) => {});
  }
  newMeeting() {
    this.router.navigate(['/meeting']);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'modal-md modal-dialog-centered home-modal',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }
  // openLinkModal(templateLink: TemplateRef<any>) {
  //   this.videoChatService.createRoom().then((meeting) => {
  //       this.newLink.link = `https://meeting.kiinder.com/video-calling/${meeting.meetingId}`;
  //       this.modalRefLink = this.modalService.show(templateLink, {
  //         class: 'modal-dialog-centered home-modal new-metng-link-modal',
  //         ignoreBackdropClick: true,
  //         keyboard: false,
  //       });
  //     })
  //     .catch((error) => {
  //       this.toastr.error(error.message);
  //       error;
  //     });
  // }
  openInvalidModal(templateInvalid: TemplateRef<any>) {
    this.modalRefnew = this.modalService.show(templateInvalid, {
      class: 'modal-md modal-dialog-centered home-modal',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }
  joinMeeting() {
    this.isMeetingId = !this.isMeetingId;
    this.isLink = false;
  }
  async joinMeetingById(meetingForm: any, template: TemplateRef<any>, templateInvalid: TemplateRef<any>) {
    let that = this;
    var id = meetingForm.split('/').pop();
    this.openModal(template);
    await this.meetingService.checkMeetingId(id).then(async (validate) => {
      if (validate.status !== 200) {
        setTimeout(() => {
          that.modalRef.hide();
        }, 150);
        return;
      }
      if (validate.data.data.checkMeetingId === null) {
        setTimeout(() => {
          that.modalRef.hide();
        }, 150);
        this.openInvalidModal(templateInvalid);
        return;
      }
      if (validate.data.data.checkMeetingId) {
        let check =
          this.compareTime(
            validate.data.data.checkMeetingId.room.startTime,
            validate.data.data.checkMeetingId.room.durationTime
          ) < new Date().getTime();
        if (check) {
          setTimeout(() => {
            that.modalRef.hide();
          }, 150);
          this.toastr.error('Meeting has been expired.', 'Error');
          return;
        }
      }
      var emailMatch = validate.data.data.checkMeetingId.attendess.find((obj: any) => obj.email === this.profile.email);
      if (!emailMatch) {
        setTimeout(() => {
          that.modalRef.hide();
        }, 150);
        this.toastr.error('You are not authorized to join this meeting.');
        return;
      }
      setTimeout(() => {
        that.modalRef.hide();
        that.getPermission(validate.data.data.checkMeetingId.room.meetingId);
      }, 150);
    });
  }
  async getPermission(meetingId: any) {
    const subject = new Subject<any>();
    this.bsModalRef = this.modalService.show(PermissionComponent, { class: 'modal-md modal-dialog-centered pp' });
    this.bsModalRef.content.subject = subject;
    subject.asObservable().subscribe(async (data) => {
      if (data) {
        this.bsModalRef.hide();
        var stream = await this.initCamera({ video: true, audio: true });
        if (stream) {
          this.getPreferences(meetingId);
        }
      } else {
        this.bsModalRef.hide();
      }
    });
  }
  getPreferences(meetingId: any) {
    const subject = new Subject<any>();
    this.bsModalRefPre = this.modalService.show(PreferencesComponent, {
      class: 'modal-md modal-dialog-centered preferecesModal',
    });
    this.bsModalRefPre.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.bsModalRefPre.hide();
        this.zone.run(() => {
          this.router.navigate(['/meet', meetingId]);
        });
      } else {
        this.bsModalRefPre.hide();
      }
    });
  }

  initCamera(config: any) {
    var browser = <any>navigator;
    browser.getUserMedia =
      browser.getUserMedia || browser.webkitGetUserMedia || browser.mozGetUserMedia || browser.msGetUserMedia;
    return browser.mediaDevices
      .getUserMedia(config)
      .then((stream: any) => {
        return true;
      })
      .catch((err: any) => {
        this.toastr.error(err, 'Error');
        return false;
      });
  }
  compareTime(startTime: any, endTime: any) {
    if (startTime && endTime) {
      let minutes = (endTime.hour * 60 + endTime.minutes) * 60000;
      let date = Number(startTime) + Number(minutes);
      return date;
    }
  }
  totalTime(startTime: any, endTime: any) {
    if (startTime && endTime) {
      var minutes = endTime.hour * 60 + endTime.minutes;
      return moment(new Date(Number(startTime)))
        .add(minutes, 'minutes')
        .format('HH:mm');
    }
  }
  editMeeting(id: any) {
    this.router.navigate(['/meeting', id]);
  }
}
