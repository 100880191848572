export function user(id: any) {
  return `query{
        user(id:${id}){
        id
          firstName
          lastName
          fullName
          email
          profilePicUrl
          role
          isActive
          firstLanguage
          otherLanguage
          gender
          isNotifications
          privacySetting
        }
      }`;
}
export function getUserByEmailId(email: any) {
  return `query{
    getUserByEmailId(email:"${email}"){
      fullName
      profilePicUrl
      email
    }
  }`;
}
