export function getChatToken(userName: any) {
  return `
    query{
        getTwilioChatToken(username:"${userName}"){
          token
          identity
        }
      }`;
}
export function getAccountChannel() {
  return `
  query{
    getAllChannels{
     sid
      friendlyName
      uniqueName
      createdBy
    }
  }`;
}
export function getInvitedMember(sid: any) {
  return `
  query{
    getInvitedMember(sid:"${sid}"
    ){
      identity
      createdBy
      sid
      channelSid
    }
  }`;
}
export function getChannelDetail(uniqueName: string) {
  return `
  query{
    getChannelDetail(uniqueName:"${uniqueName}"){
      sid
      friendlyName
      uniqueName
      createdBy
    }
  }`;
}
