import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss'],
})
export class PreferencesComponent implements OnInit {
  subject: Subject<boolean>;
  isSubmitted = false;
  name = '';
  submitted = false;
  constructor(private _auth: AuthService) {}

  ngOnInit(): void {
    var profile = this._auth.getProfileFromToken();
    this.name = profile.fullName;
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    this.subject.next(true);
    this.subject.complete();
  }
}
