import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { ContactsService } from '@app/services/contacts.service';
import { RolePermssionsService } from '@app/services/role-permssions.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
})
export class AddContactComponent implements OnInit {
  belongTo: any;
  subject: Subject<boolean>;
  obj: any;
  submitted = false;
  profile: any;
  roles: any = [];
  id: any;
  buttonLabel = '';
  modalLabel = '';
  editObj = {
    id: '',
    fullName: '',
    phone: '',
    email: '',
    belogsTo: '',
    roleId: '',
    createdByUserId: '',
    organizationId: '',
  };
  constructor(
    private _contactsService: ContactsService,
    private _auth: AuthService,
    private _rolePermissions: RolePermssionsService
  ) {
    this.profile = this._auth.getProfileFromToken();
  }
  ngOnInit(): void {
    this.buttonLabel = this.id ? 'Submit' : 'Add';
    this.modalLabel = this.id ? 'Edit Contact' : 'Add Contact';
    if (this.id) {
      this.getContactById();
    }
  }
  getContactById() {
    this._contactsService.getContactById(this.id).then((res) => {
      console.log('edit contact', res.data.data.getContactById);
      if (res === undefined) {
        return;
      }
      this.polutedValue(res.data.data.getContactById);
    });
  }
  polutedValue(obj: any) {
    this.editObj = {
      id: obj.id,
      fullName: obj.fullName,
      phone: obj.phone,
      email: obj.email,
      belogsTo: obj.belogsTo,
      roleId: obj.roleId,
      createdByUserId: obj.createdByUserId,
      organizationId: obj.organizationId,
    };
  }
  confirmMessge(bool: boolean) {
    this.subject.next(bool);
    this.subject.complete();
  }
  submit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }

    if (this.id) {
      this._contactsService.editContact(this.editObj).then((res) => {
        if (res === undefined) {
          this.submitted = false;
          return;
        }
        this.submitted = false;
        this.confirmMessge(true);
      });
    } else {
      this.obj = {
        fullName: form.value.fullName,
        phone: form.value.phone,
        email: form.value.email,
        belogsTo: this.belongTo,
        userId: this.profile.id,
      };

      this._contactsService.createContact(this.obj).then((res) => {
        if (res === undefined) {
          return;
        }
        this.confirmMessge(true);
      });
    }
  }
}
