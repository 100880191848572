<div class="kinder-meeting">
  <div class="container">
    <div class="row pt-3 pb-3">
      <div class="col-lg-12 col-md-4 col-sm-12 mx-auto mb-3 align-items-cente justify-content-center text-center">
        <div class="meeting-box mx-auto d-flex justify-content-center align-items-center">
          <p>Your camera is turned off</p>
          <nav class="navbar navbar-expand-lg navbar-dark bg-light">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <button class="mute"><i class="fas fa-microphone-slash"></i></button>
              </li>
              <li>
                <button type="button" class="btn btn-lg leave-room">
                  <img src="../../../assets/calldrop.png" />
                </button>
              </li>
              <li>
                <button class="camera"><i class="fas fa-video"></i></button>
              </li>
            </ul>
          </nav>
        </div>
        <div class="d-flex justify-content-center kinder-meeting-btn">
          <button type="submit" class="btn btn-outline">Cancel</button>
          <button type="submit" class="btn">Join Now</button>
        </div>
      </div>
    </div>
  </div>
</div>
