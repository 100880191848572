export function getContactByUserIdMeta(userId: any, searchText: any) {
  return `query{
    getContactByUserIdMeta(userId:${userId}
      searchText:"${searchText}"){
      id
      fullName
      phone
      email
      belogsTo
      createdByUserId
    }
  }`;
}
// export function getContactByUserIdOrg(userId: any, organizationId: any) {
//   return `query{
//     getContactByUserIdOrg(userId:${userId}
//     organizationId:${organizationId}){
//       id
//       fullName
//       phone
//       email
//       belogsTo
//       userId
//     }
//   }`;
// }
export function getContactById(id: any) {
  return `query{
    getContactById(id:${id}){
      id
      fullName
      phone
      createdByUserId
      email
      belogsTo
      roleId
      organizationId
    }
  }`;
}
export function getAllContactByOrganisationId(id: any, userId: any, text: any) {
  return `query{
    getAllContactByOrganisationId(
    organizationId:${id}
    userId:${userId}
    searchText:"${text}"
    ){
      id
      fullName
      email
      phone
      roleName
      profilePicUrl
      roleId
      isExist
    }
  }`;
}
