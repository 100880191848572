<div class="user-profile-listing-wrapper d-flex flex-wrap">
  <div class="users-listing-bar">
    <ul class="sideBarMenu">
      <li class="active profile-toltip-main" id="0">
        <figure class="profile-toltip" placement="right" ngbTooltip="{{ profileTool }}" (click)="profileClick()">
          <img src="{{ profileImg }}" alt="" />
        </figure>
      </li>
      <ng-container *ngFor="let org of orgs">
        <li class="profile-toltip-main" id="{{ org.Organization.id }}">
          <figure
            class="profile-toltip"
            placement="right"
            ngbTooltip="{{ org.Organization.organizationName }}"
            (click)="routeOrganization(org)"
          >
            <img
              src="{{ org.Organization.logo ? org.Organization.logo : '../../../assets/organizationAvtar.png' }}"
              alt=""
            />
          </figure>
        </li>
      </ng-container>
    </ul>
    <a class="add-users-btn profile-toltip-main" (click)="createOrganization()">
      <i class="bi bi-plus-circle-fill" placement="right" ngbTooltip="Create Organization"></i>
    </a>
  </div>
</div>
