import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { ContactsService } from '@app/services/contacts.service';
import { OrganizationService } from '@app/services/organization.service';
import { RolePermssionsService } from '@app/services/role-permssions.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { AddContactOrgComponent } from '../add-contact-org/add-contact-org.component';

@Component({
  selector: 'app-my-contact',
  templateUrl: './my-contact.component.html',
  styleUrls: ['./my-contact.component.scss'],
})
export class MyContactComponent implements OnInit {
  modalRef: BsModalRef;
  orgId: any;
  contacts: any = [];
  profile: any;
  count: any = [];
  roles: any = [];
  isLoading = false;

  constructor(
    private modalService: BsModalService,
    private _contactsService: ContactsService,
    private _organizationService: OrganizationService,
    private route: ActivatedRoute,
    private _auth: AuthService,
    private _rolePermissions: RolePermssionsService
  ) {
    this.profile = this._auth.getProfileFromToken();
    this.route.params.subscribe((params) => {
      this.orgId = window.atob(params.id);
      this.contacts = [];
      this.count = [];
      this.getAllContactByOrganisationId('');
      this.getAllRoles();
    });
  }
  ngOnInit(): void {}
  getAllRoles() {
    this.roles = [];
    this._rolePermissions.getOrganizationRoleByOrgId(this.orgId).then((res) => {
      if (res === undefined) {
        return;
      }
      this.roles = res;
      console.log('this.roles', this.roles);
    });
  }
  getAllContactByOrganisationId(text: any) {
    this.isLoading = true;
    this._contactsService.getAllContactByOrganisationId(this.orgId, this.profile.id, text).then((res) => {
      if (res === undefined) {
        this.isLoading = false;
        return;
      }
      var removeLocalContact = [];
      removeLocalContact = res.data.data.getAllContactByOrganisationId.filter(
        (item: any) => item.email !== this.profile.email
      );

      this.count = removeLocalContact.sort(function (a: any, b: any) {
        if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
          return -1;
        }
        if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      let data = [];
      data = this.count.reduce((r: any, e: any) => {
        let group = e.fullName[0].toLowerCase();
        if (!r[group]) r[group] = { group, children: [e] };
        else r[group].children.push(e);
        return r;
      }, {});
      this.contacts = Object.values(data);
      console.log('this.contacts', this.contacts);
      this.isLoading = false;
    });
  }
  addContact() {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(AddContactOrgComponent, {
      class: 'modal-md-addcontact modal-dialog-centered',
      initialState: { belongTo: 'org', orgId: this.orgId },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.getAllContactByOrganisationId('');
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  editContact(id: any) {
    const subject = new Subject<any>();
    this.modalRef = this.modalService.show(AddContactOrgComponent, {
      class: 'modal-md-editcontact modal-dialog-centered',
      initialState: { id: id, orgId: this.orgId },
    });
    this.modalRef.content.subject = subject;
    subject.asObservable().subscribe((data) => {
      if (data === true) {
        this.contacts = [];
        this.getAllContactByOrganisationId('');
        this.modalRef.hide();
      } else {
        this.modalRef.hide();
      }
    });
  }
  removeUserFromOrganisation(id: any) {
    let obj = {
      orgId: this.orgId,
      id: id,
      removedBy: this.profile.id,
    };
    this._organizationService.removeUserFromOrganisation(obj).then((res) => {
      if (res === undefined) {
        return;
      }
      this.getAllContactByOrganisationId('');
    });
  }
  deleteContact(id: any) {
    this._contactsService.deleteContact(id).then((res) => {
      if (res === undefined) {
        return;
      }
      this.getAllContactByOrganisationId('');
    });
  }
  searchText(event: any) {
    console.log('event', event.value);
    this.contacts = [];
    this.getAllContactByOrganisationId(event.value);
  }
  changeRole(event: any, id: any) {
    console.log('event.target.value', event.target.value.split(':').pop());
    let obj = {
      roleId: event.target.value.split(':').pop(),
      id: id,
    };
    this._organizationService.changeOrgMemberRole(obj).then((res) => {
      console.log('eressd', res);
    });
  }
}
