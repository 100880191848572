<div class="setup-profile-header py-2 px-3">
  <img class="home-gray" src="../../../assets/logo.png" />
</div>

<section class="create-organization-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-10">
        <div class="kinder-brand text-center">
          <img src="../../../assets/logo.svg" alt="" />
        </div>
        <div class="organization-message">
          <h2>Hi, {{ profile.fullName | titlecase }}</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </p>
        </div>
        <div class="kinder-spaces-wrapper">
          <div
            class="kinder-space-wrap d-flex align-items-center justify-content-between"
            *ngFor="let invitation of invitations; index as i"
          >
            <div class="kinder-space-info d-flex align-items-center">
              <span class="kinder-space-number">{{ n(i + 1) }}</span>
              <div class="kinder-space-info-content">
                <h3>{{ invitation.Organization.organizationName | titlecase }}</h3>
                <!-- <p>username@kiinder</p> -->
              </div>
            </div>
            <div class="kinder-space-accept-reject d-flex align-items-center">
              <button
                class="btn btn-accept"
                (click)="userAcceptInvitation(invitation.organizationId, invitation.email)"
              >
                <i class="bi bi-check2"></i>Accept
              </button>
              <button
                class="btn text-danger"
                (click)="userRejectInvitaion(invitation.organizationId, invitation.email)"
              >
                <i class="bi bi-x"></i>Reject
              </button>
            </div>
          </div>
          <!-- <div class="kinder-space-wrap d-flex align-items-center justify-content-between">
            <div class="kinder-space-info d-flex align-items-center">
              <span class="kinder-space-number">02</span>
              <div class="kinder-space-info-content">
                <h3>KiinderSpace 2</h3>
                <p>username@kiinder</p>
              </div>
            </div>
            <div class="kinder-space-accept-reject d-flex align-items-center">
              <button class="btn btn-accepted"><i class="bi bi-check2"></i>Accepted</button>
            </div>
          </div> -->
          <div class="create-new-space">
            <a (click)="createOrganization()" style="cursor: pointer">
              <i class="bi bi-plus-circle-fill"></i>
              <span>Create a new KiinderSpace</span>
            </a>
          </div>
        </div>
        <div class="kinder-next-skip">
          <!-- <button class="btn nextbtn">Next</button> -->
          <button class="btn skipbtn" (click)="skipStep()">Skip</button>
        </div>
      </div>
    </div>
  </div>
</section>
